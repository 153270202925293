import React, { ReactElement, useEffect } from "react";
import { Button, Card, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import {
  BrowserRouterProps,
  Link,
  RouteComponentProps,
} from "react-router-dom";
import RaiseableCard from "./RaiseableCard";
import ServiceRequestFileList from "./ServiceRequestFileList";
import { lightFormat } from "date-fns";
import { LinkContainer } from "react-router-bootstrap";
import { NoteCard } from "./NoteCard";
import AssignToTechnicianDialog from "./AssignToTechnicianDialog";
import { useUser } from "../data/useUser";
import { useServiceRequest } from "../data/useServiceRequest";
import { useSubmission } from "../data/useSubmission";
import { useLookups } from "../data/useLookups";
import ChangeStatusDialog from "./ChangeStatusDialog";

interface MatchParams {
  requestID: string;
}

const ServiceRequestView = (
  props: RouteComponentProps<MatchParams>
): ReactElement => {
  const {
    user: { data: user2 },
  } = useUser();

  const requestID = props.match.params.requestID;
  const {
    deleteNoteMutator,
    updateStatusMutator,
    createNoteMutator,
    serviceRequest: { data: vm },
  } = useServiceRequest(requestID);

  const { prefetchSubmission } = useSubmission(requestID);
  const { prefetchLookups } = useLookups(vm?.clientID);

  const [newNoteContent, setNewNoteContent] = React.useState("");

  const [showCancelConfirmation, setShowCancelConfirmation] =
    React.useState(false);

  useEffect(() => {
    if (!!requestID) {
      console.log("prefetching submission");
      prefetchSubmission();
    }
  }, [requestID]);

  useEffect(() => {
    if (!!vm?.clientID) {
      prefetchLookups();
    }
  }, [vm?.clientID]);

  const handleNewNoteSubmit = async (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    await createNoteMutator.mutateAsync(newNoteContent);
    setNewNoteContent("");
  };

  const cancelServiceRequest = async () => {
    await updateStatusMutator.mutateAsync("CANCELLED");
  };

  const handleCancelSRClicked = () => {
    setShowCancelConfirmation(true);
  };

  const handleDeleteNote = async (noteID: string) => {
    await deleteNoteMutator.mutateAsync(noteID);
  };

  const [showAssignToTechnicianDialog, setShowAssignToTechnicianDialog] =
    React.useState(false);

  const [showChangeStatusDialog, setShowChangeStatusDialog] =
    React.useState(false);

  const handleAssignToTechnicianClicked = () =>
    setShowAssignToTechnicianDialog(true);

  const handleUpdateStatus = async (newStatus: string) => {
    await updateStatusMutator.mutateAsync(newStatus);
  };

  return vm ? (
    <>
      <ChangeStatusDialog
        show={showChangeStatusDialog}
        handleClose={() => setShowChangeStatusDialog(false)}
        user={user2}
        handleUpdate={handleUpdateStatus}
        status={vm.status}
      />
      {/* <pre>{JSON.stringify(vm, null, 2)}</pre> */}
      {user2 && (
        <AssignToTechnicianDialog
          show={showAssignToTechnicianDialog}
          vm={vm}
          setShow={setShowAssignToTechnicianDialog}
        />
      )}
      <ConfirmCancelDialog
        show={showCancelConfirmation}
        setShow={setShowCancelConfirmation}
        cancelServiceRequest={cancelServiceRequest}
      />
      <Row>
        <Col xs={12} md={8} lg={9}>
          <Row>
            <Col>
              <h3>
                Service Request {vm.serviceRequestID}
                <small> Status: {vm.status}</small>
                {user2 && !user2.isTechnician && vm.fromQuotationID && (
                  <p>
                    Raised from{" "}
                    <Link to={"/quote/" + vm.fromQuotationID}>
                      Accepted Quote {vm.fromQuotationID}
                    </Link>
                  </p>
                )}
                <div className="float-right">
                  {vm.userPermissions?.canEdit ? (
                    <span>
                      <LinkContainer
                        to={"/servicerequest/" + vm.serviceRequestID + "/edit"}
                      >
                        <Button variant="secondary">Edit Details</Button>
                      </LinkContainer>
                    </span>
                  ) : (
                    ""
                  )}
                  {user2 && vm.userPermissions?.canAssign ? (
                    <span>
                      {" "}
                      <Button
                        variant="secondary"
                        onClick={handleAssignToTechnicianClicked}
                      >
                        Assign to a Technician
                      </Button>
                    </span>
                  ) : (
                    ""
                  )}
                  {user2?.isTechnician && (
                    <span>
                      {" "}
                      <Button
                        onClick={() => setShowChangeStatusDialog(true)}
                        variant="primary"
                      >
                        Update Job Status
                      </Button>
                    </span>
                  )}
                  {vm.userPermissions?.canCancel ? (
                    <span>
                      {" "}
                      <Button
                        title="Service requests with status LOGGED can't be cancelled"
                        variant="danger"
                        onClick={handleCancelSRClicked}
                      >
                        Cancel Service Request
                      </Button>
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </h3>
            </Col>
          </Row>

          <Row>
            <Col sm={6} style={{ marginTop: "10px", marginBottom: "10px" }}>
              <RaiseableCard className={"h-100 border-dark"}>
                <Card.Header>
                  <h4>Requester's Details</h4>
                </Card.Header>
                <Card.Body>
                  <fieldset>
                    <dl>
                      <dt>Name</dt>
                      <dd>{vm.requester.name}</dd>
                      <dt>Email</dt>
                      <dd>{vm.requester.email}</dd>
                      <dt>Mobile</dt>
                      <dd>{vm.requester.mobile || "—"}</dd>
                      <dt>Phone</dt>
                      <dd>{vm.requester.phone || "—"}</dd>
                    </dl>

                    <p>Bill to</p>

                    <p>{vm.billingDetails.companyName}</p>

                    <dl>
                      <dt>Billing Address</dt>
                      <dd>
                        <address>
                          {vm.billingDetails.streetAddress}
                          <br />
                          {vm.billingDetails.suburb}
                          <br />
                          {vm.billingDetails.city}&nbsp;&nbsp;&nbsp;
                          {vm.billingDetails.postCode}
                          <br />
                          {vm.billingDetails.country}
                        </address>
                      </dd>
                    </dl>
                  </fieldset>
                </Card.Body>
              </RaiseableCard>
            </Col>
            <Col sm={6} style={{ marginTop: "10px", marginBottom: "10px" }}>
              <RaiseableCard
                className={"h-100 border-dark"}
                border={false ? "primary" : ""}
              >
                <Card.Header>
                  <h4>Work Site Details</h4>
                </Card.Header>
                <Card.Body>
                  {vm.assignedTechnician?.userID ? (
                    <>
                      <h4>Assigned To Technician:</h4>
                      <p>{vm.assignedTechnician.name}</p>
                    </>
                  ) : (
                    <p>Not assigned to a technician</p>
                  )}
                  {/* <pre>{JSON.stringify(vm.workSiteDetails, null, 2)}</pre> */}
                  <p>{vm.workSiteDetails.siteName}</p>
                  <dl>
                    <dt>Physical worksite address</dt>
                    <dd>
                      <address>
                        {vm.workSiteDetails.streetAddress}
                        <br />
                        {vm.workSiteDetails.suburb}
                        <br />
                        {vm.workSiteDetails.city}&nbsp;&nbsp;&nbsp;
                        {vm.workSiteDetails.postCode}
                        <br />
                        {vm.workSiteDetails.country}
                      </address>
                    </dd>

                    <dt>Worksite contact</dt>
                    <dd>{vm.workSiteContact.name}</dd>
                    <dt>Worksite contact email</dt>
                    <dd>{vm.workSiteContact.email}</dd>
                    <dt>Worksite contact phone</dt>
                    <dd>{vm.workSiteContact.phone}</dd>
                    <dt>Worksite contact mobile</dt>
                    <dd>{vm.workSiteContact.mobile}</dd>
                  </dl>
                </Card.Body>
              </RaiseableCard>
            </Col>
          </Row>
          <Row>
            <Col sm={12} style={{ marginTop: "10px", marginBottom: "10px" }}>
              <RaiseableCard className="border-dark">
                <Card.Header>
                  <h4>Request Details</h4>
                </Card.Header>
                <Card.Body>
                  <fieldset>
                    <Row>
                      <Col sm={8}>{vm.jobDetails.description}</Col>
                      <Col sm={4}>
                        <dl>
                          <dt>Job Type</dt>
                          <dd>{vm.jobDetails.jobType}</dd>
                          <dt>Priority</dt>
                          <dd>{vm.jobDetails.priority}</dd>
                        </dl>

                        <Row>
                          <Col lg={6} md={12}>
                            <dl>
                              <dt>Start Date</dt>
                              <dd>
                                {vm.jobDetails.startDate &&
                                  lightFormat(
                                    Date.parse(vm.jobDetails.startDate),
                                    "dd-MM-yyyy"
                                  )}
                              </dd>
                            </dl>
                          </Col>
                          <Col lg={6} md={12}>
                            <dl>
                              <dt>End Date</dt>
                              <dd>
                                {vm.jobDetails.endDate &&
                                  lightFormat(
                                    Date.parse(vm.jobDetails.endDate),
                                    "dd-MM-yyyy"
                                  )}
                              </dd>
                            </dl>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={8}>
                        <ServiceRequestFileList uploadedFiles={vm.files} />
                      </Col>
                      <Col sm={4}>
                        <dl>
                          {vm.jobDetails.showTechConnect && (
                            <>
                              <dt>{vm.jobDetails.techConnectLabel}</dt>
                              <dd>{vm.jobDetails.techConnect}</dd>
                            </>
                          )}
                          {vm.jobDetails.showCostCenter && (
                            <>
                              <dt>{vm.jobDetails.costCenterLabel}</dt>
                              <dd>{vm.jobDetails.costCenter}</dd>
                            </>
                          )}
                        </dl>
                      </Col>
                    </Row>
                  </fieldset>
                </Card.Body>
              </RaiseableCard>
            </Col>
          </Row>
        </Col>

        <Col>
          {vm.userPermissions.canCreateNotes && (
            <Row style={{ paddingBottom: "2em", paddingTop: "2em" }}>
              <Col>
                <Form onSubmit={handleNewNoteSubmit}>
                  <fieldset disabled={createNoteMutator.isLoading}>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Form.Control
                        placeholder="Enter a job note here"
                        value={newNoteContent}
                        onChange={(ev) => setNewNoteContent(ev.target.value)}
                        as="textarea"
                        rows={5}
                      />
                    </Form.Group>
                    <Button
                      disabled={!newNoteContent}
                      className="float-right"
                      type="submit"
                    >
                      Create New Note
                    </Button>
                  </fieldset>
                </Form>
              </Col>
            </Row>
          )}
          <Row>
            <Col>
              {vm.notes.map((x) => (
                <NoteCard
                  key={x.noteID}
                  author={x.author}
                  canDelete={x.authorID === user2?.userName}
                  content={x.content}
                  handleDelete={handleDeleteNote}
                  noteID={x.noteID}
                  timestamp={x.timeStamp}
                />
              ))}
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  ) : (
    <Spinner animation="border" role="status" />
  );
};

const ConfirmCancelDialog = (props: any) => {
  const { show, setShow, cancelServiceRequest } = props;

  const hideMe = () => setShow(false);
  const handleYesClicked = () => {
    cancelServiceRequest();
    hideMe();
  };

  return (
    <Modal show={show} onHide={hideMe}>
      <Modal.Header closeButton>
        <Modal.Title>Please Confirm</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>Are you sure?</p>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={hideMe}>
          No
        </Button>
        <Button variant="danger" onClick={handleYesClicked}>
          Yes, Cancel This Service Request
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ServiceRequestView;

import { ErrorMessage } from "@hookform/error-message";
import { Form, InputGroup } from "react-bootstrap";

export const FieldGroup = (props : any) => {
    const { label, controlId, errors, register, controlAttrs, isPhone, required } =
      props;
  
  
    return (
      <Form.Group controlId={controlId}>
        <Form.Label>
          {label}
        </Form.Label>
  
        <InputGroup size="sm">
          {isPhone ? (
            <InputGroup.Prepend>
              <InputGroup.Text>+64</InputGroup.Text>
            </InputGroup.Prepend>
          ) : (
            <></>
          )}
          <Form.Control
            {...register(controlId, {required:required})}
            size="sm"
            isInvalid={errors[controlId]}
            type="text"
            {...controlAttrs}
            name={controlId}
          />
        </InputGroup>
        
        <ErrorMessage 
        errors={errors}
         name={controlId}
         render={({message}) => (
          <Form.Control.Feedback type="invalid">
            {message}
          </Form.Control.Feedback>
        ) }  />
      </Form.Group>
    );
  };
import React from "react";
import RaiseableCard from "./RaiseableCard";
import { Card, Button, Modal, Form, InputGroup, Col, ProgressBar, Spinner } from "react-bootstrap";
import { QuotationViewModel } from "../data/SharedTypes";
import { Controller, useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { acceptanceProps, useQuotation } from "../data/useQuotation";
import { QuotationFilesDropzone } from "./QuotationFilesDropzone";
import { attachEventProps } from "@aws-amplify/ui-react/lib-esm/react-component-lib/utils";
import { useSubmission } from "../data/useSubmission";

function statusText (simProQuoteStatus: string) {
    
    switch (simProQuoteStatus) {
        case "InProgress":
            return "Complete";
        case "Approved":
            return "Open";
        case "Complete":
            return "Approved";
        case "Archived":
            return "Archived";
        default:
            return simProQuoteStatus;
    }
}


  

interface QuotationPanelProps {
  vm: QuotationViewModel;
  match: any;
  simProQuoteStatus: string;
  simProQuoteID: string;
}

const QuotationPanel = ({ ...props }: QuotationPanelProps) => {
  //const [quoteGot, setQuoteGot] = React.useState(false);

  //const [quote, setQuote] = React.useState<any>();

  const requestId = props.match.params.requestID;

  //const [attachments, setAttachments] = React.useState<any[]>([]);
  const {
    attachments: { data: attachments },
    acceptQuoteMutation
  } = useQuotation(requestId);
  const [showAcceptQuoteDialog, setShowAcceptQuoteDialog] =
    React.useState(false);



  const acceptQuote = (args: acceptanceProps) => {
    acceptQuoteMutation.mutate(args);
  };

  return (
    <>
      <ConfirmAcceptanceDialog
        show={showAcceptQuoteDialog}
        vm={props.vm}
        hideDialog={() => setShowAcceptQuoteDialog(false)}
        handleAcceptQuote={acceptQuote}
      />
      <div className="mb-20">
        <RaiseableCard className={"h-100 border-info"}>
          <Card.Header>
            <h4>Quotation {props.vm && statusText(props.vm.status)}</h4>
          </Card.Header>
          <Card.Body>
            <p>Quotation for {props.match.params.requestID}</p>
            {props.vm.userPermissions.canUploadQuoteAttachments &&
            <QuotationFilesDropzone requestID = {props.vm.serviceRequestID}/>
            }
            {attachments &&
              (attachments.length === 0 ? (
                <p>No attachments</p>
              ) : (
                <ul>
                  {attachments.map((x) => (
                    <li key={x.authenticatedURL}>
                      <a
                        href={x.authenticatedURL}
                      >
                        {x.name}
                      </a>
                    </li>
                  ))}
                </ul>
              ))}
          </Card.Body>
          {/* <pre>{JSON.stringify([quoteGot, props.vm.userPermissions.canAcceptQuote, props.vm.status], null, 2)}</pre> */}
          {props.vm.userPermissions.canAcceptQuote &&
            !(props.vm.status === "Accepted") && (
              <Card.Footer>
                <Button disabled={acceptQuoteMutation.isLoading} onClick={() => setShowAcceptQuoteDialog(true)}>
                  {acceptQuoteMutation.isLoading ? <Spinner animation="border" role="status" /> : "Accept Quote"}
                </Button>
              </Card.Footer>
            )}
        </RaiseableCard>
      </div>
    </>
  );
};

interface confirmAcceptanceDialogProps {
  vm: QuotationViewModel;
  show: boolean;
  hideDialog: () => void;
  handleAcceptQuote: (arg: acceptanceProps) => void;
}

const ConfirmAcceptanceDialog = ({
  vm,
  show,
  hideDialog,
  handleAcceptQuote,
}: confirmAcceptanceDialogProps) => {
  const { formConfig, priorities, jobTypes } = vm;
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<acceptanceProps>();

  const onSubmit = (data: acceptanceProps) => {
    handleAcceptQuote(data);
    hideDialog();
  };

  return (
    <Modal show={show} onHide={hideDialog}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <Modal.Title>Accept Quote</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>
            Please supply the required Service Request information to accept
            this quote.
          </p>

          <Form.Row>
            <Col lg={6} md={12}>
              <Form.Group>
                <Form.Label>Start Date</Form.Label>
                <Form.Control
                  size="sm"
                  type="date"
                  {...register("startDate", { required: true })}
                />
                {errors.endDate && (
                  <Form.Control.Feedback type="invalid">
                    {errors.endDate.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
            <Col lg={6} md={12}>
              <Form.Group>
                <Form.Label>End Date</Form.Label>
                <Form.Control
                  size="sm"
                  type="date"
                  {...register("endDate", { required: true })}
                />
                {errors.endDate && (
                  <Form.Control.Feedback type="invalid">
                    {errors.endDate.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
          </Form.Row>

          <Form.Row>
            <Col lg={6} md={12}>
              <Form.Group controlId="jobType">
                <Form.Label>Job Type</Form.Label>
                <Controller
                  name="jobType"
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Form.Control {...field} as="select" custom>
                      {jobTypes.map((ci) => (
                        <option key={ci.code} value={ci.code}>
                          {ci.label}
                        </option>
                      ))}
                    </Form.Control>
                  )}
                  control={control}
                  defaultValue=""
                />
                {errors.jobType && (
                  <Form.Control.Feedback type="invalid">
                    {errors.jobType.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
            <Col lg={6} md={12}>
              <Form.Group controlId="priority">
                <Form.Label>Priority</Form.Label>
                <Controller
                  name="priority"
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Form.Control {...field} as="select" custom>
                      {priorities.map((ci) => (
                        <option key={ci.code} value={ci.code}>
                          {ci.label}
                        </option>
                      ))}
                    </Form.Control>
                  )}
                  control={control}
                  defaultValue=""
                />
                {errors.priority && (
                  <Form.Control.Feedback type="invalid">
                    {errors.priority.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
          </Form.Row>

          <Form.Row>
            {formConfig.showTechConnect && (
              <Col lg={6} md={12}>
                <FieldGroup
                  required
                  label={formConfig.techConnectLabel}
                  defaultValue=""
                  controlId="techConnect"
                  errors={errors}
                  register={register}
                />
              </Col>
            )}

            {formConfig.showCostCenter && (
              <Col lg={6} md={12}>
                <FieldGroup
                  label={formConfig.costCenterLabel}
                  defaultValue=""
                  controlId="costCenter"
                  errors={errors}
                  register={register}
                />
              </Col>
            )}
          </Form.Row>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={hideDialog}>
            Cancel
          </Button>
          <Button
            variant="success"
            as="input"
            type="submit"
            value="Accept Quote"
          ></Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

const FieldGroup = (props: any) => {
  const { label, controlId, errors, register, controlAttrs } = props;

  return (
    <Form.Group controlId={controlId}>
      <Form.Label>{label}</Form.Label>

      <InputGroup size="sm">
        <Form.Control
          {...register(controlId, { required: props.required })}
          size="sm"
          isInvalid={errors[controlId]}
          type="text"
          {...controlAttrs}
          name={controlId}
        />
      </InputGroup>

      <ErrorMessage
        errors={errors}
        name={controlId}
        render={({ message }) => (
          <Form.Control.Feedback type="invalid">
            {message}
          </Form.Control.Feedback>
        )}
      />
    </Form.Group>
  );
};

export default QuotationPanel;

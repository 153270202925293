import { AUTH_SOURCE_KEY } from "@aws-amplify/ui-components/dist/types/common/constants";
import { API } from "aws-amplify";
import { format, parseJSON } from "date-fns";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { UserPermissions } from "./SharedTypes";
import { CreateQuotationCommand } from "./useQuotations";

interface Note {
  noteID: string;
  authorID: string;
  author: string;
  timeStamp: string;
  noteContent: string;
}

export interface Submission {
  clientID: string;
  requestID: string;
  simProID: string;
  simProSiteID: string;
  type: "SR" | "Q";
  userIdentifier: string;
  serviceRequestID: string;
  yourName: string;
  yourEmail: string;
  yourPhone: string;
  yourMobile: string;
  billingAddress: string;
  workSiteName: string;
  workSiteEmail: string;
  workSitePhone: string;
  workSiteMobile: string;
  workSiteAddress: string;
  jobDescription: string;
  techConnectNumber: string;
  costCenterNumber: string;
  dateCreated: string;
  status: string;
  quoteStatus: string;
  customerStatus: string;
  priority: string;
  jobType: string;
  supplier: string;
  updatedByName: string;
  updatedByEmail: string;
  updatedByPhone: string;
  updatedByMobile: string;
  updatedByDateTime: string;
  updatedByUserIdentifier: string;
  dueDate: string;
  startDate: string;
  endDate: string;
  useCustomWorkSiteContact: boolean;
  assignedTo: string;
  assignedToName: string;
  simProJobID: string;
  simProQuoteID: string;
  fromQuoteRequestID: string;
  elevatedToServiceRequestID: string;
  quoteAccepted: boolean;
  notes: Note[];
  userPermissions: UserPermissions;
}

const parseDate = (str: string) => {
  return format(parseJSON(str), "yyyy-MM-dd");
};

// hopefully "submissions" will go away in favour of service requests and quotations
const fetchSubmission = async (requestId: string) => {
  console.log("fetchingSubmission...", requestId);
  const response = (await API.get("TeltracSRPortal", "/submission", {
    queryStringParameters: { requestID: requestId },
  })) as Submission;
  response.startDate = parseDate(response.startDate);
  response.endDate = parseDate(response.endDate);
  response.dueDate = parseDate(response.dueDate);
  response.dateCreated = parseDate(response.dateCreated);
  console.log("done", response);
  return response;
};

interface UpdateSubmissionCommand extends Submission {
  useCustomWorkSiteContact: boolean;
}

const postSubmission = async (command: UpdateSubmissionCommand) => {
  API.post("TeltracSRPortal", "/submission", {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: command,
  });
};

interface CancelSubmissionCommand {
  RequestId?: string;
}

const cancelSubmission = async ({ RequestId }: CancelSubmissionCommand) => {
  if (!!RequestId) {
    await API.post("TeltracSRPortal", "/submission/status", {
      body: { RequestID: RequestId, NewStatus: "CANCELLED" },
    });
  }
};

interface DeleteFileCommand {
  submissionId: string;
  requestId?: string;
  filename?: string;
  fromQuoteRequestId?: string;
}

const deleteFile = async (command: DeleteFileCommand) => {
  console.log("Delete file mutation!!", command);
  await API.del("TeltracSRPortal", "/submission/deletefile", {
    queryStringParameters: {
      submissionId: command.submissionId,
      requestId: command.requestId,
      filename: command.filename,
      fromQuoteRequestId: command.fromQuoteRequestId,
    },
  });
};

export const useSubmission = (requestId?: string, queryEnabled?: boolean) => {
  const queryClient = useQueryClient();

  return {
    prefetchSubmission: () => {
      console.log("in prefetchSubmission");
      queryClient.prefetchQuery<Submission>(["submission", requestId], () =>
        fetchSubmission(requestId!)
      );
    },
    submission: useQuery<Submission>(
      ["submission", requestId],
      () => fetchSubmission(requestId!),
      { enabled: queryEnabled && !!requestId }
    ),
    submissionMutator: useMutation((command: UpdateSubmissionCommand) =>
      postSubmission(command)
    ),
    cancelSubmissionMutation: useMutation(
      () => cancelSubmission({ RequestId: requestId }),
      {
        onSuccess: (data) => {
          queryClient.invalidateQueries(["submission", requestId]);
          queryClient.invalidateQueries(["serviceRequest", requestId]);
          queryClient.invalidateQueries(["quote", requestId]);
          queryClient.invalidateQueries("queryList");
          queryClient.invalidateQueries("serviceRequestList");
        },
      }
    ),
    deleteFileMutator: useMutation(
      (command: DeleteFileCommand) => deleteFile(command),
      { onSuccess: () => console.log("File deleted.") }
    ),
  };
};

import React from 'react';
import { Row, Col, Form, InputGroup, Button, Modal, Spinner } from 'react-bootstrap';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { API } from "aws-amplify";
import { Redirect } from "react-router-dom";

const schema = yup.object().shape({
    firstName: yup.string().label("First Name").required(),
    surname: yup.string().label("Surname").required(),
    email: yup.string().label("Email Address").email().required(),
    phoneNumber: yup.string().matches(/^\+64\d+$/, { message: "Phone number must be in the form +64...", excludeEmptyString: true }).label("Phone"),
    mobile: yup.string().matches(/^\+64\d+$/, { message: "Mobile number must be in the form +64...", excludeEmptyString: true }).label("Mobile"),
});

const FieldGroup = (props) => {


    const {
        controlId,
        errors,
        register,
        controlAttrs,
        isPhone,
        schema,
    } = props;

    return (<>

        <Form.Group controlId={controlId}>
            <Form.Label size="sm">
                {schema.fields[controlId].describe().label}
            </Form.Label>

            <InputGroup size="sm">
                {isPhone ? <InputGroup.Prepend><InputGroup.Text>+64</InputGroup.Text></InputGroup.Prepend> : <></>}
                <Form.Control
                    size="sm"
                    isInvalid={errors}
                    type="text"
                    {...controlAttrs}
                    name={controlId}
                    {...register(controlId)}
                />


                <Form.Control.Feedback type="invalid">
                    {errors && errors.message}
                </Form.Control.Feedback>
            </InputGroup>

            {
                controlId === "email" &&
                <Form.Text muted>
                    Note: If this email address is changed, the new address must be used when this user to logs in
                        </Form.Text>
            }


        </Form.Group>
    </>
    );
};

const CheckGroup = (props) => {


    const {
        controlId,
        errors,
        register,
        controlAttrs,
        schema,
    } = props;

    return (
        <Form.Group controlId={controlId}>

            <InputGroup size="sm">
                <Form.Check
                    size="sm"
                    label={schema.fields[controlId].describe().label}
                    isInvalid={errors}
                    type="checkbox"
                    {...controlAttrs}
                    name={controlId}
                    {...register(controlId)}
                />
                <Form.Control.Feedback type="invalid">
                    {errors && errors.message}
                </Form.Control.Feedback>
            </InputGroup>
            {errors && (
                <Form.Control.Feedback type="invalid">
                    {errors.message}
                </Form.Control.Feedback>
            )}
        </Form.Group>
    );
};


const NewClientUser = props => {

    const { users, setUsers } = props;

    const [userName, setUserName] = React.useState();

    const user = users.find(x => x.userName === props.match.params.userID);

    const { register, handleSubmit, watch, reset, setValue, getValues, control, formState } = useForm({
        resolver:  yupResolver(schema),
        mode: 'all',
    });

    const onSubmit = (data) => {
        setShow(true);
        console.log(data);
        //const updatedUser = { ...user, ...data };
        console.log("newUser", data);
        API.post("TeltracSRPortal", "/admin/newUser", { body: { ...data, org: props.match.params.clientID } }).then(response => {
            console.log("new user response:", response);
            setUsers(response.users);
            setUserName(response.userName);
            setShow(false);
        }
        );
    }

    const { isDirty, isSubmitting, isValid, errors } = formState;
    const [show, setShow] = React.useState(false);


    React.useEffect(() => {
        reset(user);
    }, [props.users])

    return <>
        {userName && <Redirect to={`/admin/clients/${props.match.params.clientID}/users/${userName}`} />}
        <Modal show={show}>
            <Modal.Header><Modal.Title>Saving changes...</Modal.Title></Modal.Header>
            <Modal.Body>

                <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                </Spinner>
            </Modal.Body>
        </Modal>
        <Row>
            <Col>
                <h3>New User</h3>
            </Col>
        </Row>
        <Row>
            <Col>


                <Form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>

                    <FieldGroup
                        schema={schema}
                        controlId="firstName"
                        errors={errors.firstName}
                        register={register}
                    />

                    <FieldGroup
                        schema={schema}
                        controlId="surname"
                        errors={errors.surname}
                        register={register}
                    />

                    <FieldGroup
                        schema={schema}
                        controlId="email"
                        errors={errors.email}
                        register={register}
                    />

                    <FieldGroup
                        schema={schema}
                        controlId="phoneNumber"
                        errors={errors.phoneNumber}
                        register={register}
                    />

                    <FieldGroup
                        schema={schema}
                        controlId="mobile"
                        errors={errors.mobile}
                        register={register}
                    />
                    {
                        //<CheckGroup
                        //    schema={schema}
                        //    controlId="isCustomerManager"
                        //    errors={errors.isCustomerManager}
                        //    register={register}
                        ///>
                    }




                    <div className={"float-right"}>
                        <Button disabled={!isDirty || !isValid || isSubmitting} type="submit">
                            Create User
                        </Button>
                    </div>

                </Form>

            </Col>
            <Col>
                {
                    //<pre>{JSON.stringify(user, null, 2)}</pre>
                    //<hr />
                    //<pre>{JSON.stringify(formState, null, 2)}</pre>

                }
            </Col>
        </Row>

    </>;

}

export default NewClientUser;
import React from 'react';
import { Table, Row, Col, Button, Modal, Form } from 'react-bootstrap'
import { useTable } from 'react-table';
import { API } from 'aws-amplify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortUp, faSortDown, faPlusCircle, faFilter, faTimes } from '@fortawesome/free-solid-svg-icons';


const EditableCell = ({
    value: initialValue,
    row: { index },
    column: { id },
    updateMyData, // This is a custom function that we supplied to our table instance
}) => {
    // We need to keep and update the state of the cell normally
    const [value, setValue] = React.useState(initialValue)

    const onChange = e => {
        setValue(e.target.value)
    }

    // We'll only update the external data when the input is blurred
    const onBlur = () => {
        updateMyData(index, id, value)
    }

    // If the initialValue is changed external, sync it up with our state
    React.useEffect(() => {
        setValue(initialValue)
    }, [initialValue])

    return <Form.Control size="sm" type="text" value={value} onChange={onChange} onBlur={onBlur} />
}

// Set our editable cell renderer as the default Cell renderer
const defaultColumn = {
    Cell: EditableCell,
}

// Be sure to pass our updateMyData and the skipPageReset option
function _Table({ columns, data, updateMyData, skipPageReset }) {
    // For this example, we're using pagination to illustrate how to stop
    // the current page from resetting when our data changes
    // Otherwise, nothing is different here.
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        rows,
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
            // use the skipPageReset option to disable page resetting temporarily
            autoResetPage: !skipPageReset,
            // updateMyData isn't part of the API, but
            // anything we put into these options will
            // automatically be available on the instance.
            // That way we can call this function from our
            // cell renderer!
            updateMyData,
        }
    )

    // Render the UI for your table
    return (
        <>
            <Table size="sm" hover borderless responsive {...getTableProps()}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </Table>

        </>
    )
}
const ClientSites = props => {

    const [data, setData] = React.useState([]);
    const [skipPageReset, setSkipPageReset] = React.useState(false)


    const clientID = props.match.params.clientID;

    const columns = React.useMemo(() => {
        return [
            {
                Header: 'SimPro Site ID',
                accessor: 'simProSiteID',
            },
            {
                Header: 'Site Name',
                accessor: 'siteName',
            },
            {
                Header: 'Allowed Customers',
                accessor: 'simProCustomerID',
            },
            {
                Header: 'Street Address',
                accessor: 'streetAddress',
            },
            {
                Header: 'Suburb',
                accessor: 'suburb',
            },
            {
                Header: 'City',
                accessor: 'city',
            },
            {
                Header: 'Post Code',
                accessor: 'postCode',
            },
            {
                Header: 'Country',
                accessor: 'country'
            },
        ];
    }, [])

    React.useEffect(() => {
        // get site data
        const FetchData = async () => {

            let response = await API.get("TeltracSRPortal", "/admin/sites?organisationID=" + props.match.params.clientID);
            console.log(response);
            setData(response);

        };

        FetchData();
    }, []);

    const updateMyData = (rowIndex, columnId, value) => {
        // We also turn on the flag to not reset the page
        setSkipPageReset(true)
        console.log(rowIndex, columnId, value);
        console.log(data[rowIndex]);
        const updatedRow = data[rowIndex];
        updatedRow[columnId] = value;
        API.post("TeltracSRPortal", "/admin/site", { body: updatedRow })
            .then(response => {
                console.log(response);
                setData(old =>
                    old.map((row, index) => {
                        if (index === rowIndex) {
                            return response;
                        }
                        return row;
                    })
                )
            });

    }

    const [showNewSiteModal, setShowNewSiteModal] = React.useState(false);
    const [newSimProSiteID, setNewSimProSiteID] = React.useState();
    const [newSiteName, setNewSiteName] = React.useState();

    const onCancelNewSiteModal = () => {
        setShowNewSiteModal(false);
        setNewSimProSiteID(null);
        setNewSiteName(null);
    };

    const createNewSiteRecord = () => {
        console.log(newSimProSiteID, newSiteName);

        API.post("TeltracSRPortal", "/admin/site",
            {
                body: {
                    partition: "sites:" + props.match.params.clientID,
                    simProSiteID: newSimProSiteID,
                    siteName: newSiteName
                }
            })
            .then(response => {
                console.log(response);
                setData(old => [...old, response]);
                onCancelNewSiteModal();
            });
    }

    return (
        <>
            <Modal show={showNewSiteModal}>
                <Modal.Header><Modal.Title>New Site Record</Modal.Title></Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <Form.Label>SimPro Site ID</Form.Label>
                            <Form.Control
                                type="number"
                                placeholder="###"
                                value={newSimProSiteID}
                                onChange={e => setNewSimProSiteID(e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group >
                            <Form.Label>Site Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Acme Corp."
                                value={newSiteName}
                                onChange={e => setNewSiteName(e.target.value)}
                            />
                        </Form.Group>
                    </Form>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={onCancelNewSiteModal}>Cancel</Button>{" "}
                    <Button variant="primary" onClick={createNewSiteRecord}>Create Record</Button>
                </Modal.Footer>
            </Modal>
            <Row>
                <h3>{props.match.params.clientID} Sites</h3>
            </Row>
            <Row>
                <Button variant="link" onClick={() => setShowNewSiteModal(true)}>
                    <FontAwesomeIcon icon={faPlusCircle} /> New Site Record
                        </Button>
            </Row>
            <Row>
                <Col>
                    <_Table
                        columns={columns}
                        data={data}
                        updateMyData={updateMyData}
                        skipPageReset={skipPageReset}
                    />
                </Col>
            </Row>
        </>
    );
};

export default ClientSites;
import React from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { ServiceRequestViewModel } from "../data/SharedTypes";
import { useServiceRequest } from "../data/useServiceRequest";

export interface AssignToTechnicianDialogProps {
    show : boolean,
    setShow : React.Dispatch<React.SetStateAction<boolean>>,
    vm : ServiceRequestViewModel
}

const AssignToTechnicianDialog = ({show, setShow, vm} :  AssignToTechnicianDialogProps) => {

    const { assignToTechnicianMutator } = useServiceRequest(vm.serviceRequestID);
    const [selectedTechnician, setSelectedTechnician] = React.useState<string>("");

    const hideMe = () => setShow(false);
    const handleSubmitClicked = async () => {
        await assignToTechnicianMutator.mutateAsync(selectedTechnician);
        hideMe();
    }
    const handleOnChange = (evt : React.ChangeEvent<HTMLSelectElement>) => {
        console.log(evt.target.value);
        setSelectedTechnician(evt.target.value);
    }

    React.useEffect(() => setSelectedTechnician(vm.assignedTechnician?.userID ?? vm.managedTechnicians[0]?.userID), [show, vm]);
  

  return (
    <Modal show={show} onHide={hideMe}>
      <Modal.Header closeButton>
        <Modal.Title>Assign Service Request to Technician</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {vm.managedTechnicians ? (
          <div>
            {
              <>
                <p>
                  Currently assigned to:{" "}
                  {vm?.assignedTechnician?.name || "Nobody"}
                </p>
                {/* <pre>{JSON.stringify(managedTechnicians, null, 2)}</pre> */}
              </>
            }
            <Form.Group controlId="exampleForm.ControlSelect1">
              <Form.Label>Choose a technician</Form.Label>
              <Form.Control
                value={selectedTechnician}
                onChange={handleOnChange}
                as="select"
                          >
                {vm.managedTechnicians.map((x) => (
                  <option value={x.userID} key={x.userID}>
                    {x.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </div>
        ) : (
          <div>
            <p>You have no managed technicians assigned to your branch</p>
            <p>Please consult a Teltrac admin</p>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={hideMe}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSubmitClicked}>
          Assign This Technician
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AssignToTechnicianDialog;

import { API } from "aws-amplify";
import { format, parseJSON } from "date-fns";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { DeleteNoteCommand, NewNoteCommand } from "../components/CommandTypes";
import { ServiceRequestViewModel, UserPermissions } from "./SharedTypes";

interface Attachment {
  name: string;
  size: number;
  authenticatedUrl: string;
}

// interface Note {
//     noteID : string
//     authorID : string
//     author : string
//     timeStamp : string
//     noteContent : string
// }

// interface ServiceRequest {
//     clientID : string
//     requestID : string
//     simProID : string
//     simProSiteID : string
//     type : "SR" | "Q"
//     userIdentifier : string
//     serviceRequestID : string
//     yourName : string
//     yourEmail : string
//     yourPhone : string
//     yourMobile : string
//     billingAddress : string
//     workSiteName : string
//     workSiteEmail : string
//     workSitePhone : string
//     workSiteMobile : string
//     workSiteAddress : string
//     jobDescription : string
//     techConnectNumber : string
//     costCenterNumber : string
//     dateCreated : string
//     status : string
//     quoteStatus : string
//     customerStatus : string
//     priority : string
//     jobType : string
//     supplier : string
//     updatedByName : string
//     updatedByEmail : string
//     updatedByPhone : string
//     updatedByMobile : string
//     updatedByDateTime : string
//     updatedByUserIdentifier : string
//     dueDate : string
//     startDate : string
//     endDate : string
//     useCustomWorkSiteContact : boolean
//     assignedTo : string
//     assignedToName : string
//     simProJobID : string
//     simProQuoteID : string
//     fromQuoteRequestID : string
//     elevatedToServiceRequestID : string
//     quoteAccepted : boolean
//     notes : Note[]
//     userPermissions : UserPermissions
// }

export interface UpdateServiceRequestCommand {
  requestID: string;
  serviceRequestID: string;
  simProID: string;
  simProSiteID: string;
  billingAddress: string;
  workSiteName: string;
  workSiteEmail: string;
  workSitePhone: string;
  workSiteMobile: string;
  workSiteAddress: string;
  jobDescription: string;
  techConnectNumber: string;
  costCenterNumber: string;
  jobType: string;
  priority: string;
  startDate: string;
  endDate: string;
  useCustomWorkSiteContact: boolean;
  fromQuoteRequestID: string;
}

const parseDate = (str: string) => {
  return format(parseJSON(str), "yyyy-MM-dd");
};

const getServiceRequest = async (requestId: string) => {
  const response = (await API.get("TeltracSRPortal", "/serviceRequest", {
    queryStringParameters: { requestID: requestId },
  })) as ServiceRequestViewModel;

  response.jobDetails.startDate = parseDate(response.jobDetails.startDate);
  response.jobDetails.endDate = parseDate(response.jobDetails.endDate);

  return response;
};

const createNote = async (requestId: string, noteContent: string) => {
  const body: NewNoteCommand = {
    noteContent: noteContent,
    serviceRequestID: requestId,
  };
  await API.post("TeltracSRPortal", "/ServiceRequest/NewNote", {
    body: body,
  });
};

const deleteNote = async (requestId: string, noteId: string) => {
  const command: DeleteNoteCommand = {
    noteID: noteId,
    requestID: requestId,
  };
  await API.post("TeltracSRPortal", "/ServiceRequest/DeleteNote", {
    body: command,
  });
};

interface AssignToTechnicianParams {
  requestID: string;
  technicianUserName: string;
}

const assignToTechnician = async ({
  requestID,
  technicianUserName,
}: AssignToTechnicianParams) => {
  return API.post("TeltracSRPortal", "/submission/assignToTechnician", {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: {
      requestID: requestID,
      technicianUserName: technicianUserName,
    },
  });
};

interface UpdateStatusParams {
  requestId: string;
  newStatus: string;
}

const updateStatus = async ({ requestId, newStatus }: UpdateStatusParams) => {
  return API.post("TeltracSRPortal", "/submission/status", {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: {
      requestID: requestId,
      newStatus: newStatus,
    },
  });
};

const updateServiceRequest = async (command: UpdateServiceRequestCommand) => {
  const response = (await API.put("TeltracSRPortal", "/serviceRequest", {
    body: command,
  })) as ServiceRequestViewModel;
  response.jobDetails.startDate = parseDate(response.jobDetails.startDate);
  response.jobDetails.endDate = parseDate(response.jobDetails.endDate);
  return response;
};

export const useServiceRequest = (requestId: string) => {
  const queryClient = useQueryClient();

  const refreshRequest = () => {
    queryClient.invalidateQueries(["serviceRequest", requestId]);
    queryClient.invalidateQueries("serviceRequestList");
  };

  return {
    serviceRequest: useQuery<ServiceRequestViewModel>(
      ["serviceRequest", requestId],
      () => getServiceRequest(requestId),
      {}
    ),
    assignToTechnicianMutator: useMutation(
      (technicianUserName: string) =>
        assignToTechnician({
          requestID: requestId,
          technicianUserName: technicianUserName,
        }),
      {
        onSuccess: refreshRequest,
      }
    ),
    updateStatusMutator: useMutation(
      (newStatus: string) =>
        updateStatus({ requestId: requestId, newStatus: newStatus }),
      {
        onSuccess: refreshRequest,
      }
    ),
    createNoteMutator: useMutation(
      (noteContent: string) => createNote(requestId, noteContent),
      {
        onSuccess: refreshRequest,
      }
    ),
    deleteNoteMutator: useMutation(
      (noteId: string) => deleteNote(requestId, noteId),
      { onSuccess: refreshRequest }
    ),
    updateServiceRequestMutation: useMutation(updateServiceRequest, {
      onSuccess: (data) => {
        queryClient.setQueryData(["serviceRequest", requestId], data);
        queryClient.invalidateQueries("serviceRequestList");
        queryClient.invalidateQueries(["submission", requestId]);
      },
    }),
  };
};

// export const useServiceRequestFiles = (submissionId: string) => {
//   return useQuery<Attachment[]>(["serviceRequestFiles", submissionId], () =>
//     getServiceRequestFileList(submissionId)
//   );
//};

import { Container } from "react-bootstrap";
import { NavMenu } from "./NavMenu";
//import { API } from "aws-amplify";

interface LayoutProps {
    children? : React.ReactNode
}

const Layout = (props : LayoutProps) => {

    return (
        <div >

        <Container fluid>
            
            <NavMenu />
            <div >{props.children}</div>
        </Container>
        </div>
    );

};

export default Layout;

import { formatRelative } from 'date-fns';
import React from 'react';
import { Button, Card } from 'react-bootstrap';

interface NoteCardProps {
    author: string,
    content: string,
    timestamp: string,
    canDelete: boolean,
    noteID: string,
    handleDelete: (noteID: string) => void
}

export const NoteCard = (props: NoteCardProps) => {

    const [deleting, setDeleting] = React.useState(false);

    const handleDeleteClicked = () => {
        setDeleting(true);
        props.handleDelete(props.noteID);
    }

    return (
        <Card bg="info" text="white" style={{ marginBottom: "1em" }}>
            <Card.Body>
                <Card.Title>{props.author}</Card.Title>
                <Card.Subtitle className="mb-2">{formatRelative(Date.parse(props.timestamp), new Date())}</Card.Subtitle>
                <Card.Text>{props.content}</Card.Text>
                {props.canDelete && <Button disabled={deleting} variant="outline-light" size="sm" onClick={handleDeleteClicked}>Delete Note</Button>}
            </Card.Body>
        </Card>
    );
}
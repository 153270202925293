import { useState, useEffect } from "react";
import { Route } from "react-router";
import { useHistory } from "react-router-dom";
import Layout from "./components/Layout";
//import { Home } from "./components/Home";
//import { FetchData } from "./components/FetchData";
//import { Counter } from "./components/Counter";
import { Alert } from "react-bootstrap";
//import { Auth } from 'aws-amplify';
import {
    AmplifyAuthenticator,
    AmplifySignIn,
    AmplifyForgotPassword,
} from "@aws-amplify/ui-react";
import { AuthState, AuthStateHandler, onAuthUIStateChange } from "@aws-amplify/ui-components";

import SRListMain from "./components/SRListMain";
import Admin from "./components/admin/Admin";
import ServiceRequestView from "./components/ServiceRequestView";
//import MySignIn from './MySignIn';
import UserProfile from "./components/UserProfile";
import { QuotationView } from "./components/QuotationView";
import { QuotationList } from "./components/QuotationList";

import "./custom.css";
import { QueryClientProvider, QueryClient } from "react-query";
import { NewServiceRequest } from "./components/NewServiceRequest";
import { EditServiceRequest } from "./components/EditServiceRequest";
import { NewQuotationRequest } from "./components/NewQuotationRequest";
import { EditQuotationRequest } from "./components/EditQuotationRequest";

const queryClient = new QueryClient();

const App = () => {
    const [authState, setAuthState] = useState<AuthState>();
    const [user, setUser] = useState<any>();
    const [showAlert, setShowAlert] = useState(true);

    const isAdmin = () =>
        user.signInUserSession.accessToken.payload["cognito:groups"] &&
        user.signInUserSession.accessToken.payload["cognito:groups"].includes(
            "teltrac-level1"
        );

    const authStateHandler: AuthStateHandler = (nextAuthState, authData) => {
        setAuthState(nextAuthState);
        setUser(authData);
    };

    useEffect(() => {
        onAuthUIStateChange(authStateHandler);
    }, []);

    const history = useHistory();

    useEffect(() => {
        console.log("signout effect!");
        console.log(authState);
        if (authState === "signedout") {
            queryClient.clear();
            history.push("/");
        }
    }, [authState]);

    console.log("authState:", authState);
    return authState === AuthState.SignedIn && user ? (
        <div>
            <QueryClientProvider client={queryClient}>
                <Layout>
                    <Route
                        exact
                        path="/servicerequest"
                        component={NewServiceRequest}
                    />
                    <Route
                        exact
                        path="/servicerequest/:requestID"
                        component={ServiceRequestView}
                    />
                    <Route
                        path="/servicerequest/:requestID/edit"
                        component={EditServiceRequest}
                    />
                    <Route
                        exact
                        path="/quote"
                        component={NewQuotationRequest}
                    />
                    <Route exact path="/quote/:requestID" component={QuotationView} />
                    <Route
                        path="/quote/:requestID/edit"
                        component={EditQuotationRequest}
                    />
                    <Route exact path="/user-profile" component={UserProfile} />
                    {isAdmin() && (
                        <Route path="/admin" render={() => <Admin user={user} />} />
                    )}
                    {
                        //<Route render={() => <Redirect to="/" />} />
                        //<Route exact path="/" component={Home} />
                        //<Route path="/counter" component={Counter} />
                        //<Route path="/fetch-data" component={FetchData} />
                    }
                    <Route exact path="/quotes" component={QuotationList} />
                    <Route exact path="/" component={SRListMain} />
                </Layout>
            </QueryClientProvider>
        </div>
    ) : (
        <div>
            <div>
                {authState !== undefined && (
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <img
                            style={{
                                alignSelf: "center",
                                margin: "1em",
                            }}
                            src="teltrac2.png"
                        />
                        <h3 className="text-center">
                            Welcome to the Teltrac Service Request Portal.
                        </h3>
                        <div>
                            <div className="px-4 text-center">
                                <p>
                                    We have recently upgraded to a new platform. If you have any
                                    questions, please contact Teltrac on 0800 TELTRAC or email{" "}
                                    <a href="mailto:sales@teltrac.co.nz">sales@teltrac.co.nz</a>.
                                </p>
                                <p>Kind Regards, the Teltrac Team.</p>
                            </div>
                        </div>
                        {/*
            <Alert
              variant="info"
              show={showAlert}
              onClose={() => setShowAlert(false)}
              dismissible
            >
              <Alert.Heading>
                There is a once-only process to set up your new User Account and
                to reset your password.
              </Alert.Heading>
              <p>
                Enter the email address that you use for your current SR login.
                Enter anything in the Password field (not your current password
                but it must be at least 1 character).
              </p>
              <p>
                Click the orange SIGN IN button, then continue to follow the
                prompts.{" "}
              </p>
              <p>
                Our SR portal will continue to have new features added over the
                coming days and weeks as we complete the transition to our new
                platform.
              </p>
            </Alert>

                          */}
                    </div>
                )}
                <AmplifyAuthenticator usernameAlias="email">
                    <div
                        slot="forgot-password"
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <AmplifyForgotPassword
                            formFields={[
                                {
                                    type: "email",
                                    //placeholder: "TEST",
                                    required: true,
                                    //value: email,
                                    //handleInputChange: (evt) => setEmail(evt.target.value?.toLowerCase())
                                },
                            ]}
                        ></AmplifyForgotPassword>
                    </div>
                    <div
                        slot="sign-in"
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <AmplifySignIn
                            hideSignUp
                            usernameAlias="email"
                            headerText="Sign in to your account"
                            slot="sign-in"
                            formFields={[
                                {
                                    type: "email",
                                    required: true,
                                    //value: email,
                                    //handleInputChange: (evt) => setEmail(evt.target.value.toLowerCase())
                                },
                                {
                                    type: "password",
                                    required: true,
                                },
                            ]}
                        ></AmplifySignIn>
                    </div>
                </AmplifyAuthenticator>
            </div>
        </div>
    );
};

export default App;

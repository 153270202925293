import React from "react";
import {
  Row,
  Col,
  Nav,
  Form,
  Button,
  InputGroup,
  Modal,
  Spinner,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { API } from "aws-amplify";

const schema = yup.object().shape({
  escalationDelay: yup
    .number()
    .integer()
    .positive()
    .min(1)
    .label("Escalation Delay")
    .required(),
  escalationDelayUnit: yup
    .string()
    .oneOf(["s", "m", "h", "d"])
    .label("Escalation Delay Unit")
    .required(),
  orgName: yup.string().label("Display Name").required(),
  techConnectLabel: yup.string().label("Tech Connect Field Label"),
  showTechConnect: yup
    .boolean()
    .label("Show Tech Connect Field on Service Requests"),
  costCenterLabel: yup.string().label("Cost Center Field Label"),
  showCostCenter: yup
    .boolean()
    .label("Show Cost Center Field on Service Requests"),
});

const ClientMenu = (props) => {
  const { setOrganisations } = props;

  const {
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    getValues,
    control,
    formState,
  } = useForm({
    resolver:  yupResolver(schema),
    mode: "all",
    reValidateMode: "onChange",
  });

  const { isDirty, isSubmitting, isValid, errors } = formState;

  const [organisation, setOrganisation] = React.useState();

  React.useEffect(() => {
    const getOrganisation = async () => {
      let response = await API.get(
        "TeltracSRPortal",
        "/admin/organisation?id=" + props.match.params.clientID
      );
      console.log(response);
      setOrganisation(response);
      reset(response);
    };
    getOrganisation();
  }, [props.match.params.clientID]);

  const [show, setShow] = React.useState(false);

  const onSubmit = (data) => {
    console.log(data);
    setShow(true);
    //console.log("user", user);
    //console.log("data", data);
    const updatedOrganisation = { ...organisation, ...data };
    console.log("updatedOrganisation", updatedOrganisation);
    API.post("TeltracSRPortal", "/admin/organisation", {
      body: updatedOrganisation,
    }).then((response) => {
      console.log("update user response:", response);
      setOrganisation(response);
      reset(response);
      setShow(false);
      updateOrganisations();
    });
  };

  const updateOrganisations = () => {
    API.get("TeltracSRPortal", "/admin/organisations").then((result) => {
      console.log("organisations:", result);
      setOrganisations(result);
    });
  };

  return <>
    <Modal show={show}>
      <Modal.Header>
        <Modal.Title>Saving changes...</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Spinner animation="border" role="status">
          <span className="sr-only">Saving...</span>
        </Spinner>
      </Modal.Body>
    </Modal>
    <Row>
      <Col>
        <h3>{props.match.params.clientID}</h3>
      </Col>
    </Row>
    <Row>
      <Col md={2}>
        <Nav className="flex-column">
          <LinkContainer to={`${props.match.url}/users`}>
            <Nav.Link>Users</Nav.Link>
          </LinkContainer>
          <LinkContainer to={`${props.match.url}/customers`}>
            <Nav.Link>Customers</Nav.Link>
          </LinkContainer>
          {
            //<LinkContainer to={`${props.match.url}/sites`}>
            //    <Nav.Link>Sites</Nav.Link>
            //</LinkContainer>
          }
        </Nav>
      </Col>
      <Col md={6}>
        <h3>Organisation Settings</h3>
        <Form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
          <FieldGroup
            schema={schema}
            controlId="orgName"
            errors={errors.orgName}
            register={register}
          />

          <FieldGroup
            schema={schema}
            controlId="escalationDelay"
            errors={errors.escalationDelay}
            register={register}
          />

          <FieldGroup
            schema={schema}
            controlId="escalationDelayUnit"
            errors={errors.escalationDelayUnit}
            register={register}
          />

          <FieldGroup
            schema={schema}
            controlId="techConnectLabel"
            error={errors.techConnectLabel}
            register={register}
          />

          <Form.Group>
            <Form.Check label="Show Tech Connect Field" {...register('showTechConnect')} />
          </Form.Group>

          {/* <Form.Check 
                  type="switch" label="Show Tech Connect field on Service Requests" ref={register("showTechConnect")} /> */}
          {/* <Form.Check
                  type="switch" label="Show Tech Connect field on Service Requests" register={register("showTechConnect")}></Form.Check> */}

          <FieldGroup
            schema={schema}
            controlId="costCenterLabel"
            error={errors.costCenterLabel}
            register={register}
          />

          <Form.Group>
            <Form.Check label="Show Cost Center Field" {...register('showCostCenter')} />
          </Form.Group>

          <div className={"float-right"}>
            <Button
              disabled={!isDirty || isSubmitting}
              variant="secondary"
              onClick={() => {
                console.log("errors", errors);
                reset(organisation);
              }}
            >
              Discard Changes
            </Button>{" "}
            {/* <p>{JSON.stringify([isDirty, isSubmitting])}</p> */}
            <Button disabled={!isDirty || isSubmitting} type="submit">
              Save Changes
            </Button>
          </div>
        </Form>
      </Col>
    </Row>
  </>;
};

const FieldGroup = (props) => {
  const { controlId, errors, register, controlAttrs, isPhone, schema } = props;

  return (
    <Form.Group controlId={controlId}>
      <Form.Label size="sm">
        {schema.fields[controlId].describe().label}
      </Form.Label>

      <InputGroup size="sm">
        {isPhone ? (
          <InputGroup.Prepend>
            <InputGroup.Text>+64</InputGroup.Text>
          </InputGroup.Prepend>
        ) : (
          <></>
        )}
        <Form.Control
          {...register(controlId)}
          size="sm"
          isInvalid={errors}
          type="text"
          {...controlAttrs}
        />

        <Form.Control.Feedback type="invalid">
          {errors && errors.message}
        </Form.Control.Feedback>
      </InputGroup>

      {controlId === "email" && (
        <Form.Text muted>
          Note: If this email address is changed, the new address must be used
          when this user to logs in
        </Form.Text>
      )}
    </Form.Group>
  );
};

export default ClientMenu;

import React, { useState, useEffect } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { User, useUser } from "../data/useUser";
import { FieldGroup } from "./FieldGroup";
import { useHistory } from "react-router-dom";

const schema = yup.object().shape({
    firstName: yup
        .string()
        .label("First name")
        .required(),
    surname: yup
        .string()
        .label("Surname")
        .required(),
    email: yup
        .string()
        .email()
        .required()
        .label("Your email"),
    phoneNumber: yup.string().label("Your phone").matches(/(\d[8,9])|/, "Phone number must contain only digits, no spaces, and exclude country code."),
    mobile: yup.string().label("Your mobile").matches(/(\d[9,11])|/, "Mobile number must contain only digits, no spaces, and exclude country code."),
});

const UserProfile = () => {

    const { register, handleSubmit, watch, formState:{errors}, reset, setValue, getValues } = useForm<User>({
        resolver:  yupResolver(schema)
    });

    const {user : {data:user2}, userMutation} = useUser();

    const history = useHistory();

    useEffect(() => {
        if (!!user2) {
            reset({
                firstName: user2.firstName,
                surname: user2.surname,
                email: user2.email,
                mobile: user2.mobile?.startsWith("+64") ? user2.mobile.substring(3) : user2.mobile,
                phoneNumber: user2.phoneNumber?.startsWith("+64") ? user2.phoneNumber.substring(3) : user2.phoneNumber
            });
        }

        
        }, [user2]);

    const onSubmit = async (data : User) => {
        await userMutation.mutateAsync({...user2, ...data});
        history.push("/");
    };

    return (
        user2 ?
        <Form autoComplete="off" noValidate onSubmit={handleSubmit(onSubmit)}>
            <h3>
                Update your details
            </h3>
            

                <FieldGroup
                    controlId="firstName"
                    errors={errors}
                    register={register}
                    schema={schema}
                />

                <FieldGroup
                    controlId="surname"
                    errors={errors}
                    register={register}
                    schema={schema}
                />

                <FieldGroup
                    controlId="email"
                    errors={errors}
                    register={register}
                    schema={schema}
                />

                <FieldGroup
                    controlId="phoneNumber"
                    errors={errors}
                    register={register}
                    isPhone
                    schema={schema}
                />

                <FieldGroup
                    controlId="mobile"
                    errors={errors}
                    register={register}
                    isPhone
                    schema={schema}
                />


                <Button variant="primary" type="submit">
                    Save Changes
          </Button>
            
        </Form> : <div>loading...</div>
    );
}

export default UserProfile;
import React from "react";
import { Row, Col, Button, Modal, Form, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import { API } from 'aws-amplify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortUp, faSortDown, faPlusCircle, faFilter, faTimes } from '@fortawesome/free-solid-svg-icons';

const Clients = props => {

    const { organisations, setOrganisations } = props;

    const [showNewOrgModal, setShowNewOrgModal] = React.useState(false);
    const [newOrgID, setNewOrgID] = React.useState();
    const [newOrgName, setNewOrgName] = React.useState();

    const createNewOrg = () => {
        const body = {
            partition: "org",
            orgID: newOrgID,
            orgName: newOrgName
        };
        console.log(body);
        API.post("TeltracSRPortal", "/admin/organisation", { body })
            .then(result => {
                console.log("result:", result);
                setOrganisations(old => [...old, result]);
                cancelModal();
            });
    };

    const cancelModal = () => {
        setShowNewOrgModal(false);
        setNewOrgID(null);
        setNewOrgName(null);
    }

    return (
        <>
            <Modal show={showNewOrgModal}>
                <Modal.Header>
                    <Modal.Title>
                        New Organisation
                        </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <Form.Label>Business ID</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="ACME"
                                value={newOrgID}
                                onChange={e => setNewOrgID(e.target.value)}

                            />
                        </Form.Group>

                        <Form.Group >
                            <Form.Label>Display Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Acme Corp."
                                value={newOrgName}
                                onChange={e => setNewOrgName(e.target.value)}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={cancelModal}>Cancel</Button>{" "}
                    <Button variant="primary" onClick={createNewOrg}>Create Organisation</Button>
                </Modal.Footer>
            </Modal>
            <Row>
                <Col>
                    <h4>Clients</h4>
                    <div>
                        <Button variant="link" onClick={() => setShowNewOrgModal(true)}>
                            <FontAwesomeIcon icon={faPlusCircle} /> New Organisation
                        </Button>
                    </div>

                    <Nav className="flex-column">
                        {organisations.map(org =>
                            <LinkContainer key={org.orgID} to={`clients/${org.orgID}`}>
                                <Nav.Link>{org.orgName}</Nav.Link>
                            </LinkContainer>
                        )}
                    </Nav>
                </Col>
            </Row>
        </>

    )

}

export default Clients;
import {API} from 'aws-amplify';
import {useQuery, useQueryClient} from 'react-query';
import { FormConfig, JobType, Priority } from './SharedTypes';


interface CustomerInfo {
    type? : string
    simProCustomerID : string
    companyName: string
    // firstName: string,
    // lastName: string,
    // title: string,
    streetAddress: string,
    suburb : string,
    city: string,
    postCode: string,
    country: string
}

interface SiteName {
    id : string
    name : string
}

interface SRStatus {
    code : string,
    label : string
}

export interface Lookups {
    customerInfos : CustomerInfo[],
    siteInfos : { [ id : string ] : SiteName[]},
    jobTypes : JobType[],
    priorities: Priority[],
    formConfig: FormConfig,
    quoteStatuses: SRStatus[],
    srStatuses: SRStatus[]
}

const fetchLookups = async (clientId : string) => {
    console.log("fetchLookups for:", clientId)
    const response  = (await API.get("TeltracSRPortal", "/submission/ServiceRequestLookups", {queryStringParameters:{orgID : clientId}}) ) as Lookups
    //console.log("fetchLookups response", response);
    response.customerInfos.unshift({ simProCustomerID: "", companyName: "Select", city: "", country: "", streetAddress: "", suburb: "", postCode:"" });
    response.jobTypes.unshift({ code: "", label: "Select" });
    response.priorities.unshift({ code: "", label: "Select" });
    return response;
}

export const useLookups = (clientId? : string) => {
    const queryClient = useQueryClient();

    return {
        lookups : useQuery<Lookups>(['lookups', clientId], () => fetchLookups(clientId!), { enabled:!!clientId}),
        prefetchLookups : () => !!clientId &&  queryClient.prefetchQuery(['lookups', clientId])
    }
    
}
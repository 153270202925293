import React, { useEffect } from "react";
import {
    ToggleButton,
    ToggleButtonGroup,
    Nav,
    Badge,
    Table,
    Pagination,
    Form,
    Row,
    Col,
    Button,
    InputGroup,
    OverlayTrigger,
    Tooltip,
    TooltipProps,
} from "react-bootstrap";
import {
    useTable,
    useSortBy,
    usePagination,
    useGlobalFilter,
    TableCellProps,
    CellProps,
    Column,
} from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faSort,
    faSortUp,
    faSortDown,
    faPlusCircle,
    faFilter,
    faTimes,
    faEye,
    faEdit,
} from "@fortawesome/free-solid-svg-icons";
import { format, parseISO } from "date-fns";
import { LinkContainer } from "react-router-bootstrap";
import DescriptionPopover from "./DescriptionPopover";
import Highlighter from "react-highlight-words";
import { useUser } from "../data/useUser";
import { ServiceRequestListing, useServiceRequestList } from "../data/useServiceRequests";
import { UserPermissions } from "../data/SharedTypes";
import { OverlayInjectedProps } from "react-bootstrap/esm/Overlay";
import { useLookups } from "../data/useLookups";

const SRListMain = () => {

    const [filterText, setFilterText] = React.useState("");

    const [statusFilter, setStatusFilter] = React.useState("");

    const { serviceRequests: { data } } = useServiceRequestList();


    const tableData = React.useMemo(
        () => {
            if (data) {
                if (statusFilter) {
                    return data.filter((x) => x.status?.toUpperCase() === statusFilter)
                } else {
                    return [...data];
                }
            }
            return [];
        }, [data, statusFilter]
    )

    const { user: { data: user2 } } = useUser();

    const { prefetchLookups } = useLookups(user2?.org);

    useEffect(() => {
        if (!!user2) {
            prefetchLookups();
        }
    }, [user2]);

    const columns = React.useMemo(
        () => [
            {
                Header: "ID",
                accessor: "requestID",
                Cell: (props: { value: string }) => (
                    <Highlighter
                        searchWords={[filterText]}
                        autoEscape={true}
                        textToHighlight={props.value ?? ""}
                    />
                ),
            },
            {
                Header: "Date",
                accessor: "dateCreated",
                Cell: (props: { value: string }) => (
                    <Highlighter
                        searchWords={[filterText]}
                        autoEscape={true}
                        textToHighlight={format(parseISO(props.value), "dd/MM/yyyy") ?? ""}
                    />
                ),
                disableGlobalFilter: true,
            },
            {
                Header: "Lodged by",
                accessor: "yourName",
                Cell: (props: { value: string }) => (
                    <Highlighter
                        searchWords={[filterText]}
                        autoEscape={true}
                        textToHighlight={props.value ?? ""}
                    />
                ),
            },
            {
                Header: "Supplier",
                accessor: "supplier",
                Cell: (props: { value: string }) => (
                    <Highlighter
                        searchWords={[filterText]}
                        autoEscape={true}
                        textToHighlight={props.value ?? ""}
                    />
                ),
            },
            {
                Header: "Bill to",
                accessor: "billTo",
                Cell: (props: { value: string }) => (
                    <Highlighter
                        searchWords={[filterText]}
                        autoEscape={true}
                        textToHighlight={props.value ?? ""}
                    />
                ),
            },
            {
                Header: "Site",
                accessor: "workSiteAddress",
                Cell: (props: { value: string }) => (
                    <Highlighter
                        searchWords={[filterText]}
                        autoEscape={true}
                        textToHighlight={props.value ?? ""}
                    />
                ),
            },
            {
                Header: "Description",
                accessor: "jobDescription",
                Cell: (props: { value: string }) => //(<Badge>{props.value}</Badge>),
                (<Highlighter
                    searchWords={[filterText]}
                    autoEscape={true}
                    textToHighlight={props.value ?? ""}
                />
                ),
                //disableGlobalFilter: true,
            },
            {
                Header: "Priority",
                accessor: "priority",
                Cell: (props: { value: string }) => (
                    <Highlighter
                        searchWords={[filterText]}
                        autoEscape={true}
                        textToHighlight={props.value ?? ""}
                    />
                ),
            },
            {
                Header: "Status",
                accessor: "status",
                Cell: (props: { value: string }) => (
                    <Highlighter
                        searchWords={[filterText]}
                        autoEscape={true}
                        textToHighlight={props.value ?? ""}
                    />
                ),
            },
            {
                Header: "Guid",
                accessor: "serviceRequestID",
                disableGlobalFilter: true,
            },
            {
                Header: "Assigned To",
                accessor: "assignedToName",
                Cell: (props: { value: string }) => (
                    <Highlighter
                        searchWords={[filterText && ""]}
                        autoEscape={true}
                        textToHighlight={props.value ?? ""}
                    />
                ),
            },
            {
                Header: "",
                accessor: "userPermissions",
                Cell: (props: { value: UserPermissions }) => (
                    <>
                        <OverlayTrigger overlay={readTooltipRenderer}>
                            <FontAwesomeIcon icon={faEye} />
                        </OverlayTrigger>
                        {props.value.canEdit && (
                            <>
                                {" "}
                                <OverlayTrigger overlay={editTooltipRenderer}>
                                    <FontAwesomeIcon icon={faEdit} />
                                </OverlayTrigger>
                            </>
                        )}
                    </>
                ),
                disableGlobalFilter: true,
            },
        ] as Column<ServiceRequestListing>[],
        [filterText]
    );

    const readTooltipRenderer = (props: OverlayInjectedProps) => (
        <Tooltip id="readTooltip" {...props}>
            You can view this service request
        </Tooltip>
    );

    const editTooltipRenderer = (props: OverlayInjectedProps) => (
        <Tooltip id="editTooltip" {...props}>You can edit this service request</Tooltip>
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        rows,
        prepareRow,
        // filtering stuff
        setGlobalFilter,
        // pagination stuff
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data: tableData,
            initialState: {
                sortBy: [
                    {
                        id: "requestID",
                        desc: true,
                    },
                ],
                pageSize: Number(localStorage.getItem("srListPageSize")) || 10,
                hiddenColumns: ["serviceRequestID", "supplier"],
            },
        },
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    useEffect(() => {
        console.log("setting page size", pageSize);
        localStorage.setItem("srListPageSize", pageSize.toString());
    }, [pageSize]);

    const pagination = (
        <div>
            <Row>
                <Col md="auto">
                    <span>
                        Page{" "}
                        <strong>
                            {pageIndex + 1} of {pageOptions.length}
                        </strong>{" "}
                    </span>
                </Col>
                <Col className="d-flex justify-content-center">
                    <Pagination>
                        <Pagination.First
                            onClick={() => gotoPage(0)}
                            disabled={!canPreviousPage}
                        />
                        <Pagination.Prev
                            onClick={() => previousPage()}
                            disabled={!canPreviousPage}
                        />
                        {[...Array(Math.min(5, pageCount)).keys()]
                            .map((i) =>
                                pageCount <= 5
                                    ? i
                                    : Math.min(Math.max(pageIndex, 2), pageCount - 3) + i - 2
                            )
                            .map((i) => (
                                <Pagination.Item
                                    onClick={() => gotoPage(i)}
                                    key={i}
                                    active={i === pageIndex}
                                >
                                    {i + 1}
                                </Pagination.Item>
                            ))}
                        <Pagination.Next
                            onClick={() => nextPage()}
                            disabled={!canNextPage}
                        />
                        <Pagination.Last
                            onClick={() => gotoPage(pageCount - 1)}
                            disabled={!canNextPage}
                        />
                    </Pagination>
                </Col>
                <Col md="auto">
                    <Form.Group controlId="exampleForm.ControlSelect2">
                        <Form.Control
                            as="select"
                            value={pageSize}
                            onChange={(e) => {
                                setPageSize(Number(e.target.value));
                            }}
                        >
                            {[5, 10, 20, 30, 40, 50].map((pageSize) => (
                                <option key={pageSize} value={pageSize}>
                                    Show {pageSize}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                </Col>

            </Row>
        </div>
    );

    console.log("rows:", rows);

    return (
        <>
            <Row>
                <Col sm="auto">
                    <Nav variant="pills" defaultActiveKey="/home" className="flex-column">
                        <LinkContainer exact to="/">
                            <Nav.Link>Service Requests List</Nav.Link>
                        </LinkContainer>
                        {!user2?.isTechnician &&
                            <LinkContainer exact to="/quotes">
                                <Nav.Link>Quote List</Nav.Link>
                            </LinkContainer>
                        }
                        {user2 &&
                            user2.org !== "TELTRAC" && (
                                <>
                                    <LinkContainer exact to="/servicerequest">
                                        <Nav.Link>
                                            <FontAwesomeIcon icon={faPlusCircle} /> New Service
                                            Request
                                        </Nav.Link>
                                    </LinkContainer>
                                    <LinkContainer exact to="/quote">
                                        <Nav.Link>
                                            <FontAwesomeIcon icon={faPlusCircle} /> New Quote Request
                                        </Nav.Link>
                                    </LinkContainer>
                                </>
                            )}
                        <LinkContainer exact to="/user-profile">
                            <Nav.Link>User Profile</Nav.Link>
                        </LinkContainer>
                    </Nav>
                </Col>
                <Col>
                    <Row>
                        <Col>
                            <h3>Service Requests</h3>
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: "0.5em" }}>
                        {/* <Col>
                {
                    (user2?.org !== "TELTRAC") &&
                    <LinkContainer to="/service-request">
                        <Button variant="link">
                            <FontAwesomeIcon icon={faPlusCircle} /> New Service Request
                        </Button>
                    </LinkContainer>
                }
            </Col> */}
                        <Col md="auto">
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text
                                        style={{ backgroundColor: "var(--dark)", color: "#fff" }}
                                    >
                                        <span>
                                            <FontAwesomeIcon icon={faFilter} />
                                        </span>
                                        <Badge
                                            style={{ marginLeft: "1em" }}
                                            variant={
                                                !filterText
                                                    ? "light"
                                                    : rows.length
                                                        ? "success"
                                                        : "danger"
                                            }
                                        >
                                            {rows.length}
                                        </Badge>
                                    </InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control
                                    type="text"
                                    value={filterText}
                                    placeholder="filter"
                                    onChange={(evt) => {
                                        setGlobalFilter(evt.target.value);
                                        setFilterText(evt.target.value);
                                        setStatusFilter("");
                                    }}
                                />
                                <InputGroup.Append>
                                    <Button
                                        variant="primary"
                                        disabled={filterText === ""}
                                        onClick={() => {
                                            setGlobalFilter("");
                                            setFilterText("");
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faTimes} />
                                    </Button>
                                </InputGroup.Append>
                            </InputGroup>
                        </Col>
                    </Row>

                    <Row style={{ marginBottom: "0.5em" }}>
                        <Col>
                            <ToggleButtonGroup
                                type="radio"
                                name="statusFilters"
                                value={statusFilter}
                                onChange={setStatusFilter}
                            >
                                <ToggleButton variant="secondary" value="">
                                    ALL
                                </ToggleButton>
                                <ToggleButton variant="secondary" value="PENDING">
                                    PENDING
                                </ToggleButton>
                                <ToggleButton variant="secondary" value="PROGRESS">
                                    PROGRESS
                                </ToggleButton>
                                <ToggleButton variant="secondary" value="COMPLETE">
                                    COMPLETE
                                </ToggleButton>
                                <ToggleButton variant="secondary" value="INVOICED">
                                    INVOICED
                                </ToggleButton>
                                <ToggleButton variant="secondary" value="ARCHIVED">
                                    ARCHIVED
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <Table
                                variant="dark"
                                responsive
                                hover
                                size="sm"
                                {...getTableProps()}
                            >
                                <thead>
                                    {headerGroups.map((headerGroup) => (
                                        <tr {...headerGroup.getHeaderGroupProps()}>
                                            {headerGroup.headers.map((column) => (
                                                <th
                                                    {...column.getHeaderProps(
                                                        column.getSortByToggleProps()
                                                    )}
                                                >
                                                    {column.render("Header")}&nbsp;
                                                    {column.isSorted ? (
                                                        column.isSortedDesc ? (
                                                            <FontAwesomeIcon icon={faSortDown} />
                                                        ) : (
                                                            <FontAwesomeIcon icon={faSortUp} />
                                                        )
                                                    ) : (
                                                        <FontAwesomeIcon icon={faSort} />
                                                    )}
                                                </th>
                                            ))}
                                        </tr>
                                    ))}
                                </thead>
                                <tbody {...getTableBodyProps()}>
                                    {page.map((row) => {
                                        prepareRow(row);
                                        return (
                                            <LinkContainer
                                                key={row.original.requestID}
                                                to={
                                                    "/servicerequest/" +
                                                    row.values.requestID
                                                }
                                            >
                                                <tr {...row.getRowProps()}>
                                                    {row.cells.map((cell) => {
                                                        //console.log(cell);
                                                        return cell.column.id === "jobDescription" ? (
                                                            <OverlayTrigger
                                                                trigger="hover"
                                                                placement="bottom"
                                                                delay={{ show: 250, hide: 400 }}
                                                                overlay={(props) => {
                                                                    return (
                                                                        <DescriptionPopover
                                                                            guid={row.values.serviceRequestID}
                                                                            requestid={row.values.requestID}
                                                                            overlayProps={props}
                                                                        />
                                                                    );
                                                                }}
                                                            >
                                                                <td {...cell.getCellProps()}>
                                                                    {cell.render("Cell")}
                                                                </td>
                                                            </OverlayTrigger>
                                                        ) : (
                                                            <td {...cell.getCellProps()}>
                                                                {cell.render("Cell")}
                                                            </td>
                                                        );
                                                    })}
                                                </tr>
                                            </LinkContainer>
                                        );
                                    })}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    {pagination}
                </Col>
            </Row>
        </>
    );
};

export default SRListMain;

import filesize from "filesize";
import { Button } from "react-bootstrap";
import { FileInfo } from "../data/SharedTypes";
import { useServiceRequest } from "../data/useServiceRequest";

interface ServiceRequestFileListProps {
  uploadedFiles: FileInfo[];
  deleteFile?: (file: FileInfo) => void;
}

const ServiceRequestFileList = ({
  uploadedFiles,
  deleteFile,
}: ServiceRequestFileListProps) => {
  return (
    <>
      <ul>
        {uploadedFiles &&
          uploadedFiles.map((file) => (
            <li key={file.name}>
              <a href={file.authenticatedURL} download>
                {file.name} ({filesize(file.size)})
              </a>{" "}
              {deleteFile && (
                <Button variant="link" onClick={() => deleteFile(file)}>
                  delete file
                </Button>
              )}
            </li>
          ))}
      </ul>
      {uploadedFiles && uploadedFiles.length === 0 && <p>No uploaded files</p>}
    </>
  );
};

export default ServiceRequestFileList;

import React, { RefObject } from "react";
import { Popover } from "react-bootstrap";
import filesize from "filesize";
import {
  useServiceRequest
} from "../data/useServiceRequest";
import { OverlayInjectedProps } from "react-bootstrap/esm/OverlayTrigger";

const DescriptionPopover = React.forwardRef(
  (props: { requestid: string; guid: string; overlayProps: any }) => {
    const {
      serviceRequest: { data: request },
    } = useServiceRequest(props.requestid);

    return (
      <Popover id={props.guid} {...props.overlayProps}>
        <Popover.Title as="h3">
          {props.requestid} <small>Description</small>
        </Popover.Title>
        <Popover.Content>
          <pre style={{ whiteSpace: "pre-wrap" }}>
            {request?.jobDetails?.description || "Loading…"}
          </pre>
          <hr />
          <div>
            {request?.files ?  (
              request.files.length > 0 ? (
                <>
                  <p>Attachments:</p>
                  <ul
                    style={{
                      paddingInlineStart: "20px",
                    }}
                  >
                    {request.files.map((file) => (
                      <li key={file.name}>
                        {file.name} ({filesize(file.size)})
                      </li>
                    ))}
                  </ul>
                </>
              ) : (
                <p>No attachments</p>
              )
            ) : (
              <p>Loading…</p>
            )}
          </div>
        </Popover.Content>
      </Popover>
    );
  }
);

export default DescriptionPopover;

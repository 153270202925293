import { Modal, Button, Spinner } from 'react-bootstrap';
import { QuotationViewModel } from '../data/SharedTypes';

interface ServiceRequestSubmittingModalProps {
    show: boolean
    quote: boolean
}

const ServiceRequestSubmittingModal = ({ show, quote }: ServiceRequestSubmittingModalProps) => {

    return (<Modal show={show} backdrop="static"
        keyboard={false}>
        
            <Modal.Header>
            <Modal.Title>{`Submitting ${quote ? "quote" : "service" } request…`}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}>
                <Spinner animation="border" variant="primary" />
            </div>
          
            </Modal.Body>
        
    </Modal>);


}

export default ServiceRequestSubmittingModal;
import React from "react";
import {
    Container,
    Navbar,
    Nav    //NavbarToggler,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import "./NavMenu.css";
import { AmplifySignOut } from "@aws-amplify/ui-react";
import { useUser } from "../data/useUser";

export const NavMenu = () => {

    const { user: { data: user2 } } = useUser();

    const [collapsed, setCollapsed] = React.useState(true);

    const toggleNavbar = () => {
        setCollapsed(x => !x);
    }


    return (
        <header>

            <Navbar
                className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3"
                variant="light"
            >
                <Container fluid>
                    <Navbar.Brand >
                        <Link to="/">
                            <img src="/teltrac2.png" width="50%" />

                        </Link>

                        <Link className="pl-4" to="/">Main Menu</Link>
                    </Navbar.Brand>
                    {
                        //<NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
                    }
                    <Navbar.Collapse
                        className="d-sm-inline-flex flex-sm-row-reverse"
                    >
                        <ul className="navbar-nav flex-grow">
                            {user2 && user2.isTeltracLevel1 &&
                                <Nav.Item>

                                    <Nav.Link as={Link} className="text-dark" to="/admin">
                                        Admin
                                    </Nav.Link>

                                </Nav.Item>
                            }
                            <Nav.Item>
                                <AmplifySignOut />
                            </Nav.Item>
                        </ul>
                    </Navbar.Collapse>
                </Container>
            </Navbar>

        </header>
    );

}

import React, { useState, useEffect } from "react";
import { Modal, Button, ButtonGroup, ToggleButtonGroup, ToggleButton } from "react-bootstrap";

const ChangeStatusDialog = ({ status, show, handleClose, handleUpdate, user }) => {

    const [value, setValue] = useState();
    const handleChange = (val) => setValue(val);

    useEffect(() => {
        setValue(status)
    }, [status]);

    const values = user && user.org === "TELTRAC" ?
        ["LOGGED", "CANCELLED", "ASSIGNED", "IN PROGRESS", "COMPLETE", "INVOICED"]
        : ["IN PROGRESS", "COMPLETED"];

    return (
        <Modal show={show}>
            <Modal.Header>
                <Modal.Title>Change Status</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <ToggleButtonGroup value={value} name="statusRadio" vertical onChange={handleChange}>
                    {
                        values && values.map(value => <ToggleButton key={value} variant="outline-dark" value={value}>{value}</ToggleButton>)
                    }
                </ToggleButtonGroup>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>Cancel</Button>
                <Button variant="primary" onClick={() => { handleUpdate(value); handleClose(); }}>Update</Button>
            </Modal.Footer>

        </Modal>
    );



}

export default ChangeStatusDialog;
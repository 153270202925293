import React from "react";
import { Row, Col, Button, Badge, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortUp, faSortDown, faPlusCircle, faFilter, faTimes } from '@fortawesome/free-solid-svg-icons';
import { LinkContainer } from "react-router-bootstrap";


const Staff = props => {



    return (
        <Row>
            <Col>
                <h4>Staff</h4>
                <LinkContainer to="staff/new-user">
                    <Button variant="link">
                        <FontAwesomeIcon icon={faPlusCircle} /> New User
                    </Button>
                </LinkContainer>
                <Table>
                    <thead>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Info</th>
                    </thead>
                    <tbody>
                        {props.users.filter(x => x.org === "TELTRAC")
                            .map(x => <tr key={x.userName}>
                                <td>
                                    <Link to={`staff/${x.userName}`} className={!x.enabled && "text-secondary"} >{x.firstName} {x.surname}</Link>
                                </td>
                                <td>
                                    {x.email}
                                </td>
                                <td>
                                    {!x.enabled && <><Badge variant="secondary">Disabled</Badge>{" "}</>}
                                    {x.isTeltracDispatcher && <><Badge variant="success">Dispatcher</Badge>{" "}</>}
                                    {x.isTeltracLevel1 && <><Badge variant="primary">Level 1 Admin</Badge>{" "}</>}
                                    {x.isTeltracAccountManager && <><Badge variant="info">Account Manager</Badge>{" "}</>}
                                    {x.isBranchManager && <><Badge variant="dark">Branch Manager</Badge>{" "}</>}
                                    {x.isTechnician && <><Badge variant="warning">Technician</Badge>{" "}</>}
                                </td>

                            </tr>)}
                    </tbody>
                </Table>

            </Col>
        </Row>
    );


}

export default Staff;
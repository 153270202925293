import React from 'react';
import { Row, Col, Form, InputGroup, Button, Modal, Spinner } from 'react-bootstrap';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { API } from "aws-amplify";
import Select from 'react-select';
import ResetPasswordModal from './ResetPasswordModal';

const schema = yup.object().shape({
    firstName: yup.string().label("First Name").required(),
    surname: yup.string().label("Surname").required(),
    email: yup.string().label("Email Address").email().required(),
    isCustomerManager: yup.bool().label("Manager"),
    isCustomerLevel1: yup.bool().label("User Level 1"),
    phoneNumber: yup.string().matches(/^\+64\d+$/, { message: "Phone number must be in the form +64...", excludeEmptyString: true }).label("Phone"),
    mobile: yup.string().matches(/^\+64\d+$/, { message: "Mobile number must be in the form +64...", excludeEmptyString: true }).label("Mobile"),
    enabled: yup.bool().label("User Account Enabled"),
    managedUsers: yup.array()
        .of(yup.string()
            .transform((val, origVal) => { return origVal.value; }))
        .label("Managed Users")
});



const FieldGroup = (props) => {


    const {
        controlId,
        errors,
        register,
        controlAttrs,
        isPhone,
        schema,
    } = props;

    return (


        <Form.Group controlId={controlId}>
            <Form.Label size="sm">
                {schema.fields[controlId].describe().label}
            </Form.Label>

            <InputGroup size="sm">
                {isPhone ? <InputGroup.Prepend><InputGroup.Text>+64</InputGroup.Text></InputGroup.Prepend> : <></>}
                <Form.Control
                    size="sm"
                    isInvalid={errors}
                    type="text"
                    {...controlAttrs}
                    name={controlId}
                    {...register(controlId)}
                />


                <Form.Control.Feedback type="invalid">
                    {errors && errors.message}
                </Form.Control.Feedback>
            </InputGroup>

            {
                controlId === "email" &&
                <Form.Text muted>
                    Note: If this email address is changed, the new address must be used when this user to logs in
                        </Form.Text>
            }


        </Form.Group>
    );
};

const CheckGroup = (props) => {


    const {
        controlId,
        errors,
        register,
        controlAttrs,
        schema,
        note
    } = props;

    return (
        <Form.Group controlId={controlId}>

            <InputGroup size="sm">
                <Form.Check
                    size="sm"
                    label={schema.fields[controlId].describe().label}
                    isInvalid={errors}
                    type="checkbox"
                    {...controlAttrs}
                    name={controlId}
                    {...register(controlId)}
                />
                <Form.Control.Feedback type="invalid">
                    {errors && errors.message}
                </Form.Control.Feedback>
            </InputGroup>
            {errors && (
                <Form.Control.Feedback type="invalid">
                    {errors.message}
                </Form.Control.Feedback>
            )}
            {
                note &&
                <Form.Text muted>
                    {note}
                </Form.Text>
            }
        </Form.Group>
    );
};

//const options = [
//    { value: 'chocolate', label: 'Chocolate' },
//    { value: 'strawberry', label: 'Strawberry' },
//    { value: 'vanilla', label: 'Vanilla' }
//]


const ClientUser = props => {

    const { users, setUsers } = props;

    const [user, setUser] = React.useState();

    const [showResetPassword, setShowResetPassword] = React.useState(false);

    const { register, handleSubmit, watch, reset, setValue, getValues, control, formState } = useForm({
        resolver:  yupResolver(schema),
        mode: 'all',
    });

    const watchIsCustomerManager = watch("isCustomerManager");

    const onSubmit = (data) => {
        setShow(true);
        console.log(data);
        const updatedUser = { ...user, ...data };
        console.log("updatedUser", updatedUser);
        API.post("TeltracSRPortal", "/admin/staffUser", { body: updatedUser }).then(response => {
            console.log("update user response:", response);
            setUsers(response);
            setShow(false);
        }
        );
    }

    const { isDirty, isSubmitting, isValid, errors } = formState;
    const [show, setShow] = React.useState(false);

    const options = React.useMemo(() => {
        console.log(props.match.params.clientID);
        return props.users ? props.users.filter(x => x.org == props.match.params.clientID.toUpperCase())
            .map(x => {
                return { value: x.userName, label: `${x.firstName} ${x.surname}` }
            }) : []
    }
        , [users, props.match.params.clientID]);

    React.useEffect(() => {
        if (users) {
            const getUser = async () => {
                let response = await API.get("TeltracSRPortal", "/admin/user?userName=" + props.match.params.userID)
                console.log("setting user:", response);
                response.managedUsers = (response.managedUsers ?? []).map(x => {
                    let managedUser = users.find(y => y.userName === x);
                    if (managedUser) {
                        return { value: x, label: `${managedUser.firstName} ${managedUser.surname}` }
                    } else {
                        return { value: x, label: "not found" };
                    }
                });
                setUser(response);
                reset(response);
            }
            getUser();
        }

    }, [users]);

    const handleResetPasswordButtonClick = () => setShowResetPassword(true);


    return (user ? <>
        <ResetPasswordModal
            show={showResetPassword}
            setShow={setShowResetPassword}
            email={user.email}
            userID={user.userName}
        />
        <Modal show={show}>
            <Modal.Header><Modal.Title>Saving changes...</Modal.Title></Modal.Header>
            <Modal.Body>

                <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                </Spinner>
            </Modal.Body>
        </Modal>
        <Row>
            <Col>
                <h3>{user.firstName} {user.surname} <small>{user.email}</small></h3>
            </Col>
            <Col><Button onClick={handleResetPasswordButtonClick} variant="link" >Reset Password</Button></Col>
        </Row>
        <Row>
            <Col>


                <Form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>

                    <FieldGroup
                        schema={schema}
                        controlId="firstName"
                        errors={errors.firstName}
                        register={register}
                    />

                    <FieldGroup
                        schema={schema}
                        controlId="surname"
                        errors={errors.surname}
                        register={register}
                    />

                    <FieldGroup
                        schema={schema}
                        controlId="email"
                        errors={errors.email}
                        register={register}
                    />

                    <FieldGroup
                        schema={schema}
                        controlId="phoneNumber"
                        errors={errors.phoneNumber}
                        register={register}
                    />

                    <FieldGroup
                        schema={schema}
                        controlId="mobile"
                        errors={errors.mobile}
                        register={register}
                    />

                    <CheckGroup
                        schema={schema}
                        controlId="isCustomerLevel1"
                        errors={errors.isCustomerLevel1}
                        register={register}
                        note="Level 1 users have access to all of their organisation's service requests."
                    />

                    <CheckGroup
                        schema={schema}
                        controlId="isCustomerManager"
                        errors={errors.isCustomerManager}
                        register={register}
                        note="Managers have access to the service requests of their managed users."
                    />


                    <div hidden={!watchIsCustomerManager}>
                        <p>Managed Users:</p>
                        <Controller
                            control={control}
                            name="managedUsers"
                            render={({field}) => <Select {...field} options={options} isMulti />}
                            onChange={([selected]) => { console.log(selected); return selected }}

                        />
                    </div>
                    <hr />
                    <CheckGroup
                        schema={schema}
                        controlId="enabled"
                        errors={errors.enabled}
                        register={register}
                        note="Disabled users cannot log on to the service request portal."
                    />


                    <div className={"float-right"}>
                        <Button disabled={!isDirty || isSubmitting} variant="secondary" onClick={() => reset(user)}>
                            Discard Changes
                        </Button>{" "}

                        <Button disabled={!isDirty || isSubmitting} type="submit">
                            Save Changes
                        </Button>
                    </div>

                </Form>

            </Col>
            <Col>
                {
                    //<pre>{JSON.stringify(user, null, 2)}</pre>
                    //<hr />
                    //<pre>{JSON.stringify(formState, null, 2)}</pre>

                }
            </Col>
        </Row>


    </> : <><p>loading...</p></>
    );
}

export default ClientUser;
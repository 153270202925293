import React, { useEffect, useState } from 'react';
import {
    Switch,
    Route,
    Link,
    useParams,
    useLocation,
    useRouteMatch
} from "react-router-dom";
import useBreadcrumbs from 'use-react-router-breadcrumbs';

import {
    LinkContainer
} from "react-router-bootstrap";
import { API } from 'aws-amplify';
import { Breadcrumb } from 'react-bootstrap';
import Staff from './Staff';
import AdminHome from './AdminHome';
import Clients from './Clients';
import ClientUsers from './ClientUsers';
import StaffUser from './StaffUser';
import ClientUser from './ClientUser';
import NewStaffUser from './NewStaffUser';
import NewClientUser from './NewClientUser';
import ClientMenu from './ClientMenu';
import ClientSites from './ClientSites';
import ClientCustomers from './ClientCustomers';
import ClientCustomersSimpro from './ClientCustomersSimpro';


const DynamicClientBreadcrumb = ({ match }) => <span style={{}}>{match.params.clientID}</span>


const Admin = (props) => {

    const { user } = props.user;
    const [users, setUsers] = useState([]);
    const [organisations, setOrganisations] = useState([]);

    const DynamicUserBreadcrumb = ({ match }) => {
        let user = users && users.find(x => x.userName === match.params.userID);
        return <span>{user ? user.firstName : "..."}</span>;
    }

    useEffect(() => {
        API.get("TeltracSRPortal", "/admin/users")
            .then(result => {
                console.log("admin result:", result);
                setUsers(result);
            })
        API.get("TeltracSRPortal", "/admin/organisations")
            .then(result => {
                console.log("organisations:", result);
                setOrganisations(result);
            })
    }, [])


    const rm = useRouteMatch();
    const location = useLocation();
    const { path, url } = rm;

    const routeConfig = [
        {
            //exact: true,
            path: `/admin/staff/new-user`,
            component: NewStaffUser,
            breadcrumb: "New User"
        },
        {
            //exact: true,
            path: `/admin/staff/:userID`,
            component: StaffUser,
            breadcrumb: DynamicUserBreadcrumb
        },
        {
            //exact: true,
            path: `/admin/staff`,
            component: Staff
        },
        {
            path: `/admin/clients/:clientID/users/new-user`,
            component: NewClientUser,
            breadcrumb: "New User"
        },
        {
            path: `/admin/clients/:clientID/users/:userID`,
            component: ClientUser,
            breadcrumb: DynamicUserBreadcrumb
        },
        {
            path: `/admin/clients/:clientID/users`,
            component: ClientUsers,
        },
        {
            path: `/admin/clients/:clientID/sites`,
            component: ClientSites,
        },
        {
            path: `/admin/clients/:clientID/customers`,
            component: ClientCustomersSimpro,
        },
        //{
        //    path: `/admin/clients/:clientID/customers`,
        //    component: ClientCustomers,
        //},
        {
            path: `/admin/clients/:clientID`,
            component: ClientMenu,
        },
        {
            //exact: true,
            path: `/admin/clients`,
            component: Clients
        },
        {
            path: `/admin`,
            component: AdminHome,
            user: props.user,
        },
    ]

    const breadcrumbs = useBreadcrumbs(routeConfig);

    return <>
        <Breadcrumb>

            {breadcrumbs.map(({ match, breadcrumb }) =>
                <LinkContainer key={match.url} to={match.url}>
                    <Breadcrumb.Item active={url === location.pathname}>{breadcrumb}</Breadcrumb.Item>
                </LinkContainer>
            )}


        </Breadcrumb>

        <Switch>
            {routeConfig.map((route, i) =>
                <Route key={i} path={route.path}
                    render={props => (
                        <route.component
                            {...props}
                            url={url}
                            user={route.user}
                            users={users}
                            setUsers={setUsers}
                            organisations={organisations}
                            setOrganisations={setOrganisations}
                        />


                    )


                    }


                />


            )
            }
        </Switch>

    </>

}

export default Admin;
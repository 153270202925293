import styled, { css } from "styled-components";
import { Card } from "react-bootstrap";

interface RaiseableCardProps {
  raised : boolean,
  disabled : boolean
}

const RaiseableCard = styled(Card)`
  margin-top: 10px;
  margin-bottom: 10px;
  transition: box-shadow 0.4s;
  ${(props : RaiseableCardProps) =>
        props.raised &&
        css`
      box-shadow: 0 0.5rem 1.5rem rgba(0,0,0,.3);
    `}
   ${(props : RaiseableCardProps) =>
        props.disabled &&
        css`
       pointer-events: none;
       opacity: 0.4;
    `}
`;

export default RaiseableCard;

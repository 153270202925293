import React, { useCallback } from "react";
import useFileUploader from "react-uploader-hook";
import styled, { ThemedStyledProps } from "styled-components";
import { DropzoneRootProps, useDropzone } from "react-dropzone";
import { ProgressBar } from "react-bootstrap";
import { Auth } from "aws-amplify";
import { FileInfo } from "../data/SharedTypes";
import { useQueryClient } from "react-query";
import { useQuotation } from "../data/useQuotation";

const getColor  = (props : DropzoneRootProps) => {
    if (props.isDragAccept) {
        return "#00e676";
    }
    if (props.isDragReject) {
        return "#ff1744";
    }
    if (props.isDragActive) {
        return "#2196f3";
    }
    return "#6c757d";
};

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  min-height: 180px;
  border-color: ${props => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #6c757d;
  outline: none;
  cursor: pointer;
  transition: border 0.24s ease-in-out;
`;

interface QuotationFilesDropzoneProps {
    requestID : string
}

export const QuotationFilesDropzone = (props : QuotationFilesDropzoneProps) => {



    // const getUploadParams = useCallback(file => {

    //     const getJwt = async () => {

    //         // [💡] you can return custom request configurations here
    //         const form = new FormData();
    //         form.append("files", file);
    //         form.append("requestID", props.requestID);
    //         console.log(form);
    //         return {
    //             method: "post",
    //             url: "/api/quotation/quotationfiles",
    //             headers: {
    //                 "Content-Type": "multipart/form-data",
    //                 "Authorization": `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
    //             },
    //             data: form,
    //             meta: { key: file.path, size: file.size }
    //         }
    //     }
    //     return getJwt();
    // }, [props.requestID]);

    const {uploadQuotationAttachmentMutator} = useQuotation(props.requestID);

    const onUploaded = useCallback((fileBag, fileBags) => {
        // [💡] do whatever with the uploaded files
        console.log("onUploaded:", fileBag);
    }, []);

    const onDrop = useCallback((acceptedFiles : File[]) => {
        // do something with the files
        console.log(acceptedFiles);
        acceptedFiles.forEach(f => {
            console.log("in loop...");
            uploadQuotationAttachmentMutator.mutate(f);
        });
    }, []);

    //const { onDrop, fileBags } = useFileUploader({ getUploadParams, onUploaded });

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject
    } = useDropzone({
        //accept: "image/*",
        onDrop
    });

    // const files = fileBags.map((file, i) => {
    //     console.log("files func:", file);
    //     return file.progress < 100 ? (
    //         <ProgressBar
    //             animated
    //             now={file.progress}
    //             label={`${file.progress}%`}
    //             key={file.id}
    //         />
    //     ) : (
    //         <></>
    //             // <p key={file.id}>{file.file.name}</p>
    //         );
    // });

    return (
        props.requestID ?
            <div
                style={{
                    flex: 1
                }}
            >
                <Container
                    {...getRootProps({ isDragActive, isDragAccept, isDragReject })}
                >
                    <input {...getInputProps()} />
                    <p>
                        <span style={{ verticalAlign: "middle" }} className="material-icons">
                            cloud_upload
          </span>{" "}
          Drag and drop to attach quotation documents
        </p>
                    {/* {fileBags && files} */}
                </Container>
            </div> : <p>loading...</p>
    );
};


import React from 'react';
import { Row, Col, Form, InputGroup, Button, Modal, Spinner } from 'react-bootstrap';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { API } from "aws-amplify";
import Select from 'react-select';
import ResetPasswordModal from './ResetPasswordModal';

const schema = yup.object().shape({
    firstName: yup.string().label("First Name").required(),
    surname: yup.string().label("Surname").required(),
    email: yup.string().label("Email Address").email().required(),
    isTeltracDispatcher: yup.bool().label("Teltrac Dispatcher"),
    isTeltracLevel1: yup.bool().label("Teltrac Admin Level 1"),
    isBranchManager: yup.bool().label("Branch Manager"),
    isTechnician: yup.bool().label("Technician"),
    isTeltracAccountManager: yup.bool().label("Account Manager"),
    phoneNumber: yup.string().matches(/^\+64\d+$/, { message: "Phone number must be in the form +64...", excludeEmptyString: true }).label("Phone"),
    mobile: yup.string().matches(/^\+64\d+$/, { message: "Mobile number must be in the form +64...", excludeEmptyString: true }).label("Mobile"),
    enabled: yup.bool().label("User Account Enabled"),
    managedAccounts: yup.array()
        .of(yup.string()
            .transform((val, origVal) => { return origVal.value; })).nullable()
        .label("Managed Accounts"),
    managedTechnicians: yup.array()
        .of(yup.string()
            .transform((val, origVal) => { return origVal.value; })).nullable()
        .label("Managed Technicians"),
});

const FieldGroup = (props) => {

    const {
        controlId,
        errors,
        register,
        controlAttrs,
        isPhone,
        schema,
    } = props;

    return (
        <Form.Group controlId={controlId}>
            <Form.Label size="sm">
                {schema.fields[controlId].describe().label}
            </Form.Label>

            <InputGroup size="sm">
                {isPhone ? <InputGroup.Prepend><InputGroup.Text>+64</InputGroup.Text></InputGroup.Prepend> : <></>}
                <Form.Control
                    size="sm"
                    isInvalid={errors}
                    type="text"
                    {...controlAttrs}
                    name={controlId}
                    {...register(controlId)}
                />


                <Form.Control.Feedback type="invalid">
                    {errors && errors.message}
                </Form.Control.Feedback>
            </InputGroup>

            {
                controlId === "email" &&
                <Form.Text muted>
                    Note: If this email address is changed, the new address must be used when this user to logs in
                        </Form.Text>
            }


        </Form.Group>
    );
};

const CheckGroup = (props) => {


    const {
        controlId,
        errors,
        register,
        controlAttrs,
        schema,
        note,
    } = props;

    return (
        <Form.Group controlId={controlId}>

            <InputGroup size="sm">
                <Form.Check
                    size="sm"
                    label={schema.fields[controlId].describe().label}
                    isInvalid={errors}
                    type="checkbox"
                    {...controlAttrs}
                    name={controlId}
                    {...register(controlId)}
                />
                <Form.Control.Feedback type="invalid">
                    {errors && errors.message}
                </Form.Control.Feedback>
            </InputGroup>
            {errors && (
                <Form.Control.Feedback type="invalid">
                    {errors.message}
                </Form.Control.Feedback>
            )}
            {
                note &&
                <Form.Text muted>
                    {note}
                </Form.Text>
            }
        </Form.Group>
    );
};

const StaffUser = props => {

    const { users, setUsers, organisations } = props;

    const [user, setUser] = React.useState();

    const { register, handleSubmit, watch, reset, setValue, getValues, control, formState  } = useForm({
        resolver:  yupResolver(schema),
        mode: 'all',
        reValidateMode: 'onChange',
    });

    const watchIsTeltracAccountManager = watch("isTeltracAccountManager");
    const watchIsBranchManager = watch("isBranchManager");

    const onSubmit = (data) => {
        setShow(true);
        console.log("user", user);
        console.log("data", data);
        const updatedUser = { ...user, ...data };
        console.log("updatedUser", updatedUser);
        API.post("TeltracSRPortal", "/admin/staffUser", { body: updatedUser }).then(response => {
            console.log("update user response:", response);
            setUsers(response);
            setShow(false);
        }
        );
    }

    const { isDirty, isSubmitting, isValid, errors } = formState;
    const [show, setShow] = React.useState(false);
    const [showResetPassword, setShowResetPassword] = React.useState(false);

    const accountManagerOptions = React.useMemo(() => {
        return organisations ? organisations
            .map(x => {
                return { value: x.orgID, label: x.orgName }
            }) : []
    }
        , [organisations]);

    const technicianOptions = React.useMemo(() => {
        return users ? users.filter(x => x.org === "TELTRAC" && x.isTechnician)
            .map(x => {
                return { value: x.userName, label: `${x.firstName} ${x.surname}` }
            }) : [];
    }, [users]);

    React.useEffect(() => {

        if (users) {
            const getUser = async () => {
                let response = await API.get("TeltracSRPortal", "/admin/user?userName=" + props.match.params.userID)
                console.log("setting user:", response);
                response.managedAccounts = (response.managedAccounts ?? []).map(x => {
                    let account = organisations.find(y => y.orgID === x);
                    if (account) {
                        return { value: x, label: account.orgName }
                    } else {
                        return { value: x, label: "not found" };
                    }
                });
                response.managedTechnicians = (response.managedTechnicians ?? []).map(x => {
                    let technician = users.find(y => y.userName === x);
                    if (technician) {
                        return { value: x, label: `${technician.firstName} ${technician.surname}` }
                    } else {
                        return { value: x, label: "not found" };
                    }
                });
                setUser(response);
                reset(response);
            }
            getUser();
        }

    }, [props.users])

    const handleResetPasswordButtonClick = () => setShowResetPassword(true);

    return (user ? <>
        <ResetPasswordModal
            show={showResetPassword}
            setShow={setShowResetPassword}
            email={user.email}
            userID={user.userName}
        />
        <Modal show={show}>
            <Modal.Header><Modal.Title>Saving changes...</Modal.Title></Modal.Header>
            <Modal.Body>

                <Spinner animation="border" role="status">
                    <span className="sr-only">Saving...</span>
                </Spinner>
            </Modal.Body>
        </Modal>
        <Row>
            <Col>
                <div><h3>{user.firstName} {user.surname} <small>{user.email}</small></h3></div>
            </Col><Col><Button onClick={handleResetPasswordButtonClick} variant="link" >Reset Password</Button></Col>
        </Row>
        <Row>
            <Col>


                <Form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>

                    <FieldGroup
                        schema={schema}
                        controlId="firstName"
                        errors={errors.firstName}
                        register={register}
                    />

                    <FieldGroup
                        schema={schema}
                        controlId="surname"
                        errors={errors.surname}
                        register={register}
                    />

                    <FieldGroup
                        schema={schema}
                        controlId="email"
                        errors={errors.email}
                        register={register}
                    />

                    <FieldGroup
                        schema={schema}
                        controlId="phoneNumber"
                        errors={errors.phoneNumber}
                        register={register}
                    />

                    <FieldGroup
                        schema={schema}
                        controlId="mobile"
                        errors={errors.mobile}
                        register={register}
                    />

                    <CheckGroup
                        schema={schema}
                        controlId="isTeltracDispatcher"
                        errors={errors.isTeltracDispatcher}
                        register={register}
                        note="Dispatchers have full access to all service requests."
                    />

                    <CheckGroup
                        schema={schema}
                        controlId="isTeltracLevel1"
                        errors={errors.isTeltracLevel1}
                        register={register}
                        note="Level 1 admins have full access to all service requests plus access to the admin screens."
                    />

                    <CheckGroup
                        schema={schema}
                        controlId="isTechnician"
                        errors={errors.isTechnician}
                        register={register}
                        note="Technicians can view service requests assigned to them by their branch manager, and can update service request status."
                    />
                    <hr />
                    <CheckGroup
                        schema={schema}
                        controlId="isBranchManager"
                        errors={errors.isBranchManager}
                        register={register}
                        note="Branch managers can assign service requests to their managed technicians."
                    />


                    <div hidden={!watchIsBranchManager}>
                        <p>Managed Technicians:</p>
                        <Controller
                            control={control}
                            name="managedTechnicians"
                            render={({field})=><Select {...field} options={technicianOptions} isMulti />}
                            onChange={([selected]) => { console.log(selected); return selected }}

                        />
                    </div>

                    <hr />

                    <CheckGroup
                        schema={schema}
                        controlId="isTeltracAccountManager"
                        errors={errors.isTeltracAccountManager}
                        register={register}
                        note="Account managers have read-only access to services requests of their managed account(s)."
                    />


                    <div hidden={!watchIsTeltracAccountManager}>
                        <p>Managed Accounts:</p>
                        <Controller
                            control={control}
                            name="managedAccounts"
                            render={({field}) => <Select {...field} options={accountManagerOptions} isMulti />}
                            onChange={([selected]) => { console.log(selected); return selected }}

                        />
                    </div>
                    <hr />
                    <CheckGroup
                        schema={schema}
                        controlId="enabled"
                        errors={errors.enabled}
                        register={register}
                        note="Disabled users cannot log on to the service request portal."
                    />
                    {
                        //<>
                        //    <pre>{JSON.stringify(errors)}</pre>
                        //    <pre>{isDirty ? "isDirty" : "!isDirty"} {isValid ? "isValid" : "!isValid"} {isSubmitting ? "isSubmitting" : "!isSubmitting"}</pre>
                        //</>
                    }


                    <div className={"float-right"}>
                        <Button disabled={!isDirty || isSubmitting} variant="secondary" onClick={() => { console.log("errors", errors); reset(user); }}>
                            Discard Changes
                        </Button>{" "}

                        <Button disabled={!isDirty || isSubmitting} type="submit">
                            Save Changes
                        </Button>
                    </div>

                </Form>

            </Col>
            <Col>
                {
                    //<pre>{JSON.stringify(user, null, 2)}</pre>
                    //<hr />
                    //<pre>{JSON.stringify(formState, null, 2)}</pre>

                }
            </Col>
        </Row>

    </> : <p>loading...</p>
    );
}

export default StaffUser;
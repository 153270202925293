import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
//import registerServiceWorker from './registerServiceWorker';
import { unregister } from './registerServiceWorker';
import Amplify, { Auth } from 'aws-amplify';
import awsconfig from './aws-exports';



Amplify.configure({
    Auth: {
        //authenticationFlowType: 'USER_PASSWORD_AUTH'
    },
    API: {
        endpoints: [
            {
                name: "TeltracSRPortal",
                endpoint: "/api",
                custom_header: async () => {
                    //return { Authorization: 'token' }
                    // Alternatively, with Cognito User Pools use this:
                    //console.log((await Auth.currentSession()).getIdToken().getJwtToken());
                    return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
                }
            }
        ]
    },
    ...awsconfig
});



//const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

ReactDOM.render(
    <React.StrictMode>
   <BrowserRouter>
        <App />
    </BrowserRouter>
    </React.StrictMode>,
    rootElement);

//registerServiceWorker();
unregister();

import React, { ReactElement } from "react";
import { Button, Card, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import { Link, RouteComponentProps } from "react-router-dom";
import QuotationPanel from "./QuotationPanel";
import RaiseableCard from "./RaiseableCard";
import ServiceRequestFileList from "./ServiceRequestFileList";
import { lightFormat } from "date-fns";
import { LinkContainer } from "react-router-bootstrap";
import { NoteCard } from "./NoteCard";
import { useQuotation } from "../data/useQuotation";
import { useUser } from "../data/useUser";
import { useSubmission } from "../data/useSubmission";

interface MatchParams {
  requestID: string;
}

function statusText(simProQuoteStatus: string) {
  switch (simProQuoteStatus) {
    case "InProgress":
      return "Complete";
    case "Approved":
      return "Open";
    case "Complete":
      return "Approved";
    case "Archived":
      return "Archived";
    default:
      return simProQuoteStatus;
  }
}

export const QuotationView = (
  props: RouteComponentProps<MatchParams>
): ReactElement => {
  const {
    quotation: { data: vm },
  } = useQuotation(props.match.params.requestID);
  const {
    user: { data: user2 },
  } = useUser();
  const username = user2?.userName;

  const [newNoteContent, setNewNoteContent] = React.useState("");
  const [newNoteEnabled, setNewNoteEnabled] = React.useState(true);
  const [showCancelConfirmation, setShowCancelConfirmation] =
    React.useState(false);
  const [showAcceptanceDialog, setShowAcceptanceDialog] = React.useState(false);

  const { cancelSubmissionMutation } = useSubmission(
    props.match.params.requestID
  );

  const hideAcceptanceDialog = () => {
    setShowAcceptanceDialog(false);
  };

  const handleNewNoteSubmit = async (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    // setNewNoteEnabled(false);
    // const body: NewNoteCommand = {
    //   noteContent: newNoteContent,
    //   serviceRequestID: vm?.serviceRequestID ?? "",
    // };
    // await axios.post("/api/quotation/NewNote", body, {
    //   headers: await headers(),
    // });
    // load();
    // setNewNoteContent("");
    // setNewNoteEnabled(true);
  };

  const updateStatus = (newStatus: string) => {
    // API.post("TeltracSRPortal", "/quotation/status", {
    //   headers: {
    //     Accept: "application/json",
    //     "Content-Type": "application/json",
    //   },
    //   body: {
    //     requestID: props.match.params.requestID ?? vm?.serviceRequestID,
    //     newStatus: newStatus,
    //   },
    // }).then(() => {
    //   load();
    // });
  };

  const cancelServiceRequest = () => {
    cancelSubmissionMutation.mutate();
  };

  const handleCancelSRClicked = () => {
    setShowCancelConfirmation(true);
  };

  const deleteNote = async (noteID: string) => {
    // if (vm) {
    //   const command: DeleteNoteCommand = {
    //     noteID: noteID,
    //     requestID: vm.serviceRequestID,
    //   };
    //   await axios.post("/api/quotation/DeleteNote", command, {
    //     headers: await headers(),
    //   });
    //   load();
    // }
  };

  const handleDeleteNote = (noteID: string) => {
    deleteNote(noteID);
  };

  return vm ? (
    <>
      <ConfirmCancelDialog
        show={showCancelConfirmation}
        setShow={setShowCancelConfirmation}
        cancelServiceRequest={cancelServiceRequest}
      />

      <Row>
        <Col xs={12} md={8} lg={9}>
          {vm.simProQuoteID && (
            <Row>
              <Col style={{ marginTop: "10px", marginBottom: "10px" }}>
                <QuotationPanel
                  vm={vm}
                  match={props.match}
                  simProQuoteStatus={vm.status}
                  simProQuoteID={vm.simProQuoteID}
                />
              </Col>
            </Row>
          )}

          <Row>
            <Col>
              <h3>
                Quote {vm.serviceRequestID}
                <small> Status: {statusText(vm.status)}</small>
                {vm.elevatedToServiceRequestID && (
                  <p>
                    Accepted quote raised as{" "}
                    <Link
                      to={"/servicerequest/" + vm.elevatedToServiceRequestID}
                    >
                      Service Request {vm.elevatedToServiceRequestID}
                    </Link>
                  </p>
                )}
                <div className="float-right">
                  {vm.userPermissions?.canEdit ? (
                    <span>
                      <LinkContainer
                        to={"/quote/" + vm.serviceRequestID + "/edit"}
                      >
                        <Button variant="secondary">Edit Details</Button>
                      </LinkContainer>
                    </span>
                  ) : (
                    ""
                  )}
                  {/* {vm.userPermissions?.canAssign ? (
                    <span>
                      {" "}
                      <Button variant="secondary">
                        Assign to a Technician
                      </Button>
                    </span>
                  ) : (
                    ""
                  )} */}


                  {/*{vm.userPermissions?.canCancel ? (*/}
                  {/*  <span>*/}
                  {/*    {" "}*/}
                  {/*    <Button*/}
                  {/*      disabled={cancelSubmissionMutation.isLoading}*/}
                  {/*      variant="danger"*/}
                  {/*      onClick={handleCancelSRClicked}*/}
                  {/*    >*/}
                  {/*      Cancel Quote Request*/}
                  {/*    </Button>*/}
                  {/*  </span>*/}
                  {/*) : (*/}
                  {/*  ""*/}
                  {/*                )}*/}


                </div>
              </h3>
            </Col>
          </Row>

          <Row>
            <Col sm={6} style={{ marginTop: "10px", marginBottom: "10px" }}>
              <RaiseableCard className={"h-100 border-dark"}>
                <Card.Header>
                  <h4>Requester's Details</h4>
                </Card.Header>
                <Card.Body>
                  <fieldset>
                    <dl>
                      <dt>Name</dt>
                      <dd>{vm.requester.name}</dd>
                      <dt>Email</dt>
                      <dd>{vm.requester.email}</dd>
                      <dt>Mobile</dt>
                      <dd>{vm.requester.mobile || "—"}</dd>
                      <dt>Phone</dt>
                      <dd>{vm.requester.phone || "—"}</dd>
                    </dl>

                    <p>Bill to</p>

                    <p>{vm.billingDetails.companyName}</p>

                    <dl>
                      <dt>Billing Address</dt>
                      <dd>
                        <address>
                          {vm.billingDetails.streetAddress}
                          <br />
                          {vm.billingDetails.suburb}
                          <br />
                          {vm.billingDetails.city}&nbsp;&nbsp;&nbsp;
                          {vm.billingDetails.postCode}
                          <br />
                          {vm.billingDetails.country}
                        </address>
                      </dd>
                    </dl>
                  </fieldset>
                </Card.Body>
              </RaiseableCard>
            </Col>
            <Col sm={6} style={{ marginTop: "10px", marginBottom: "10px" }}>
              <RaiseableCard
                className={"h-100 border-dark"}
                border={false ? "primary" : ""}
              >
                <Card.Header>
                  <h4>Work Site Details</h4>
                </Card.Header>
                <Card.Body>
                  <p>{vm.workSiteDetails.siteName}</p>
                  <dl>
                    <dt>Physical worksite address</dt>
                    <dd>
                      <address>
                        {vm.workSiteDetails.streetAddress}
                        <br />
                        {vm.workSiteDetails.suburb}
                        <br />
                        {vm.workSiteDetails.city}&nbsp;&nbsp;&nbsp;
                        {vm.workSiteDetails.postCode}
                        <br />
                        {vm.workSiteDetails.country}
                      </address>
                    </dd>

                    <dt>Worksite contact</dt>
                    <dd>{vm.workSiteContact.name}</dd>
                    <dt>Worksite contact email</dt>
                    <dd>{vm.workSiteContact.email}</dd>
                    <dt>Worksite contact phone</dt>
                    <dd>{vm.workSiteContact.phone}</dd>
                    <dt>Worksite contact mobile</dt>
                    <dd>{vm.workSiteContact.mobile}</dd>
                  </dl>
                </Card.Body>
              </RaiseableCard>
            </Col>
          </Row>
          <Row>
            <Col sm={12} style={{ marginTop: "10px", marginBottom: "10px" }}>
              <RaiseableCard className="border-dark">
                <Card.Header>
                  <h4>Request Details</h4>
                </Card.Header>
                <Card.Body>
                  <fieldset>
                    <Row>
                      <Col sm={8}>{vm.jobDetails.description}</Col>
                      <Col sm={4}>
                        <Row>
                          <Col lg={6} md={12}>
                            <dl>
                              <dt>Quote Due Date</dt>
                              <dd>
                                {vm.jobDetails.dueDate &&
                                  lightFormat(
                                    Date.parse(vm.jobDetails.dueDate),
                                    "dd-MM-yyyy"
                                  )}
                              </dd>
                            </dl>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={8}>
                        <ServiceRequestFileList uploadedFiles={vm.files} />
                      </Col>
                    </Row>
                  </fieldset>
                </Card.Body>
              </RaiseableCard>
            </Col>
          </Row>
        </Col>

        {/* <Col>
          <Row style={{ paddingBottom: "2em" }}>
            <Col>
              <Form onSubmit={handleNewNoteSubmit}>
                <fieldset disabled={!newNoteEnabled}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Control
                      placeholder="Enter a job note here"
                      value={newNoteContent}
                      onChange={(ev) => setNewNoteContent(ev.target.value)}
                      as="textarea"
                      rows={5}
                    />
                  </Form.Group>
                  <Button
                    disabled={!newNoteContent}
                    className="float-right"
                    type="submit"
                  >
                    Create New Note
                  </Button>
                </fieldset>
              </Form>
            </Col>
          </Row>
          <Row>
            <Col>
              {vm.notes.map((x) => (
                <NoteCard
                  key={x.noteID}
                  author={x.author}
                  canDelete={x.authorID === username}
                  content={x.content}
                  handleDelete={handleDeleteNote}
                  noteID={x.noteID}
                  timestamp={x.timeStamp}
                />
              ))}
            </Col>
          </Row>
        </Col> */}
      </Row>
    </>
  ) : (
    <Spinner animation="border" role="status" />
  );
};

const ConfirmCancelDialog = (props: any) => {
  const { show, setShow, cancelServiceRequest } = props;

  const hideMe = () => setShow(false);
  const handleYesClicked = () => {
    cancelServiceRequest();
    hideMe();
  };

  return (
    <Modal show={show} onHide={hideMe}>
      <Modal.Header closeButton>
        <Modal.Title>Please Confirm</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>Are you sure?</p>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={hideMe}>
          No
        </Button>
        <Button variant="danger" onClick={handleYesClicked}>
          Yes, Cancel This Service Request
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

import { API } from "aws-amplify";
import { useMutation, useQuery, useQueryClient } from "react-query";

export interface User {
  userName: string;
  org: string;
  firstName: string;
  middleName: string;
  surname: string;
  email: string;
  mobile: string;
  phoneNumber: string;
  enabled: boolean;
  isCustomerLevel1: boolean;
  isTeltracLevel1: boolean;
  isTeltracAccountManager: boolean;
  isCustomerManager: boolean;
  isTeltracDispatcher: boolean;
  isTechnician: boolean;
  isBranchManager: boolean;
  managedUsers: string[];
  managedAccounts: string[];
  managedTechnicians: string[];
}

const getUser = async () => {
  const user = await API.get("TeltracSRPortal", "/submission/user", undefined);
  return user;
};

const updateUser = async(data : User) => {
  const response  = await API.post("TeltracSRPortal", "/submission/updatemydetails", { body: data });
  return response as User;
}

// User type could be narrowed for this!
// const fetchManagedTechnicians : User[] = async () => {
//   const response = await  API.get("TeltracSRPortal", "/submission/managedTechnicians")
//   return response as User[];
// }

export const useUser = () => {

  const queryClient = useQueryClient();

  return {
    user : useQuery<User>("user", getUser),
    userMutation : useMutation((data : User) => updateUser(data), {onSuccess: data => {
      queryClient.setQueryData('user', data);
    }} )
    
  };
};

import { useState, useEffect, useMemo } from "react";
import { Card, Row, Col, Form, Button } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import ServiceRequestDropzone from "./ServiceRequestDropzone";
import ServiceRequestFileList from "./ServiceRequestFileList";
import { v4 as uuidv4 } from "uuid";
import ServiceRequestSubmittingModal from "./ServiceRequestSubmittingModal";
import { LinkContainer } from "react-router-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import RaiseableCard from "./RaiseableCard";
import { useSiteInfo } from "../data/useSiteInfo";
import { useLookups } from "../data/useLookups";
import { useUser } from "../data/useUser";
import { FieldGroup } from "./FieldGroup";
import { RouteComponentProps } from "react-router-dom";
import { FileInfo } from "../data/SharedTypes";
import { DevTool } from "@hookform/devtools";
import { CreateQuotationCommand, useQuotations } from "../data/useQuotations";

interface MatchParams {
  requestID: string;
}

export const NewQuotationRequest = (
  props: RouteComponentProps<MatchParams>
) => {
  const {
    user: { data: user2 },
  } = useUser();

  const {
    lookups: { data: lookups },
  } = useLookups(user2?.org);

  const { createQuotationMutation } = useQuotations();

  const [useCustomWorkSiteContact, setUseCustomWorkSiteContact] =
    useState<boolean>(false);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    control,
  } = useForm<CreateQuotationCommand>({});

  const onSubmit = async (data: CreateQuotationCommand) => {
    data.useCustomWorkSiteContact = useCustomWorkSiteContact;
    data.serviceRequestID = serviceRequestID;
    console.log("onSubmit data", data);
    console.log("useCustomWorkSiteContact", useCustomWorkSiteContact);
    await createQuotationMutation.mutateAsync(data);
  };

  const watchSimProID = watch("simProID");
  const watchSimProSiteID = watch("simProSiteID");

  const selectedCustomerInfo = useMemo(() => {
    return lookups?.customerInfos.find(
      (obj) => obj.simProCustomerID === watchSimProID
    );
  }, [watchSimProID, lookups?.customerInfos]);

  const {
    siteInfo: { data: selectedSiteInfo },
  } = useSiteInfo(watchSimProSiteID);

  const [submissionErrors, setSubmissionErrors] = useState<string[]>([]);

  const [uploadedFiles, setUploadedFiles] = useState<FileInfo[]>([]);

  const deleteFile = (file: FileInfo): void => {
    setUploadedFiles((uf) => uf.filter((x) => x !== file));
  };

  //const [lastState, setLastState] = useState(uuidv4); // purely to force a re-render when user details are updated
  const [serviceRequestID, setServiceRequestID] = useState<string>("");
  //const {  submission : { data : serviceRequest } } = useSubmission(serviceRequestID);

  useEffect(() => {
    setServiceRequestID(uuidv4().toString());
  }, []);

  useEffect(() => {
    console.log(watchSimProID);
    if (!lookups?.siteInfos[watchSimProID] && selectedSiteInfo) {
      console.log("SimproID changed. resetting simProSiteID");
      if (!lookups?.siteInfos[watchSimProSiteID]) {
        setValue("simProSiteID", "");
      }
    }
  }, [watchSimProID, setValue, lookups]);

  return (
    <>
      <DevTool placement="top-right" control={control} />
      {/* <pre>{JSON.stringify(serviceRequest, null, 2)}</pre> */}
      <ServiceRequestSubmittingModal
        quote={true}
        show={createQuotationMutation.isLoading}
      />
      {createQuotationMutation.isSuccess ? (
        <>
          <div>
            <h3>
              Thanks! <small>Quotation request received 👍</small>
            </h3>
          </div>
          <div>
            <LinkContainer to="/quotes">
              <Button variant="link">
                <FontAwesomeIcon icon={faArrowLeft} /> Back to quotation request
                list
              </Button>
            </LinkContainer>
          </div>
        </>
      ) : (
        <>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col sm={6} style={{ marginTop: "10px", marginBottom: "10px" }}>
                <RaiseableCard className={"h-100 border-dark"}>
                  <Card.Header>
                    <h4>Requester's Details</h4>
                  </Card.Header>
                  <Card.Body>
                    <fieldset>
                      <dl>
                        <dt>Name</dt>
                        <dd>
                          {user2?.firstName} {user2?.surname}
                        </dd>
                        <dt>Email</dt>
                        <dd>{user2?.email}</dd>
                        <dt>Mobile</dt>
                        <dd>{user2?.mobile || "—"}</dd>
                        <dt>Phone</dt>
                        <dd>{user2?.phoneNumber || "—"}</dd>
                      </dl>

                      <Form.Group controlId="simProIDGroup">
                        <Form.Label>Bill to</Form.Label>
                        <Controller
                          name="simProID"
                          rules={{ required: true }}
                          render={({ field }) => (
                            <Form.Control
                              {...field}
                              isInvalid={!!errors.simProID}
                              as="select"
                            >
                              {lookups?.customerInfos.map((ci) => (
                                <option
                                  key={ci.simProCustomerID}
                                  value={ci.simProCustomerID}
                                >
                                  {ci.companyName}
                                </option>
                              ))}
                            </Form.Control>
                          )}
                          control={control}
                          defaultValue=""
                        />
                        {errors.simProID && (
                          <Form.Control.Feedback type="invalid">
                            {errors.simProID.message}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                      {watchSimProID && (
                        <dl>
                          <dt>Billing Address</dt>
                          <dd>
                            <address>
                              {selectedCustomerInfo?.streetAddress}
                              <br />
                              {selectedCustomerInfo?.suburb}
                              <br />
                              {selectedCustomerInfo?.city}&nbsp;&nbsp;&nbsp;
                              {selectedCustomerInfo?.postCode}
                              <br />
                              {selectedCustomerInfo?.country}
                            </address>
                          </dd>
                        </dl>
                      )}
                    </fieldset>
                  </Card.Body>
                </RaiseableCard>
              </Col>
              <Col sm={6} style={{ marginTop: "10px", marginBottom: "10px" }}>
                <RaiseableCard
                  disabled={!watchSimProID}
                  className={"h-100 border-dark"}
                  border={false ? "primary" : ""}
                >
                  <Card.Header>
                    <h4>Work Site Details</h4>
                  </Card.Header>

                  <Card.Body>
                    <>
                      {/* <div>{serviceRequest.simProSiteID}</div> */}
                      {/* <div>{watchSimProID}</div> */}

                      <Form.Group controlId="simProSiteIDGroup">
                        {/* <Form.Label>Site</Form.Label> */}
                        {true && (
                          <Controller
                            rules={{ required: true }}
                            name="simProSiteID"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                              <Form.Control
                                {...field}
                                disabled={!watchSimProID}
                                isInvalid={!!errors.simProSiteID}
                                as="select"
                                custom
                              >
                                <option key="0" value="">
                                  SELECT
                                </option>
                                <>
                                  {lookups?.siteInfos[watchSimProID] &&
                                    lookups.siteInfos[watchSimProID].map(
                                      (ci) => (
                                        <option key={ci.id} value={ci.id}>
                                          {ci.name}
                                        </option>
                                      )
                                    )}
                                </>
                              </Form.Control>
                            )}
                          />
                        )}
                        {errors.simProSiteID && (
                          <Form.Control.Feedback type="invalid">
                            {errors.simProSiteID.message}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                      {selectedSiteInfo && (
                        <>
                          <dl>
                            <dt>Physical worksite address</dt>
                            <dd>
                              <address>
                                {selectedSiteInfo.streetAddress}
                                <br />
                                {selectedSiteInfo.suburb}
                                <br />
                                {selectedSiteInfo.city}&nbsp;&nbsp;&nbsp;
                                {selectedSiteInfo.postCode}
                                <br />
                                {selectedSiteInfo.country}
                              </address>
                            </dd>
                            <dt></dt>
                            <dd>
                              <Form.Switch
                                checked={useCustomWorkSiteContact}
                                onChange={(e) =>
                                  setUseCustomWorkSiteContact(e.target.checked)
                                }
                                type="checkbox"
                                id="test"
                                label="Use custom contact details"
                              />
                            </dd>
                            {!useCustomWorkSiteContact && (
                              <>
                                <dt>Worksite contact</dt>
                                <dd>
                                  {selectedSiteInfo.primaryContactFirstName}{" "}
                                  {selectedSiteInfo.primaryContactLastName}
                                </dd>
                                <dt>Worksite contact email</dt>
                                <dd>{selectedSiteInfo.primaryContactEmail}</dd>
                                <dt>Worksite contact phone</dt>
                                <dd>
                                  {selectedSiteInfo.primaryContactWorkPhone}
                                </dd>
                                <dt>Worksite contact mobile</dt>
                                <dd>
                                  {selectedSiteInfo.primaryContactMobilePhone}
                                </dd>
                              </>
                            )}
                          </dl>
                          {useCustomWorkSiteContact && (
                            <div>
                              <FieldGroup
                                label="Worksite contact"
                                controlId="workSiteName"
                                required
                                errors={errors}
                                register={register}
                              />

                              <FieldGroup
                                label="Worksite contact email"
                                controlId="workSiteEmail"
                                errors={errors}
                                register={register}
                              />

                              <FieldGroup
                                label="Worksite contact phone"
                                controlId="workSitePhone"
                                errors={errors}
                                register={register}
                                isPhone
                              />

                              <FieldGroup
                                label="Worksite contact mobile"
                                controlId="workSiteMobile"
                                errors={errors}
                                register={register}
                                isPhone
                              />
                            </div>
                          )}
                        </>
                      )}
                    </>
                  </Card.Body>
                </RaiseableCard>
              </Col>
            </Row>
            <Row>
              <Col sm={12} style={{ marginTop: "10px", marginBottom: "10px" }}>
                <RaiseableCard className="border-dark">
                  <Card.Header>
                    <h4>Request Details</h4>
                  </Card.Header>
                  <Card.Body>
                    <fieldset>
                      <Row>
                        <Col sm={8}>
                          <FieldGroup
                            controlId="jobDescription"
                            required
                            errors={errors}
                            register={register}
                            controlAttrs={{ as: "textarea", rows: "5" }}
                          />
                        </Col>
                        <Col sm={4}>
                          <>
                            {/* <Form.Group controlId="jobType">
                              <Form.Label>
                                Job Type
                              </Form.Label>
                              <Controller
                              rules={{required:true}}
                                name="jobType"
                                render={({ field }) => (
                                  <Form.Control
                                    {...field}
                                    isInvalid={!!errors.jobType}
                                    as="select"
                                    custom
                                  >
                                    {lookups?.jobTypes.map((ci) => (
                                      <option key={ci.code} value={ci.code}>
                                        {ci.label}
                                      </option>
                                    ))}
                                  </Form.Control>
                                )}
                                control={control}
                                defaultValue=""
                              />
                              {errors.jobType && (
                                <Form.Control.Feedback type="invalid">
                                  {errors.jobType.message}
                                </Form.Control.Feedback>
                              )}
                            </Form.Group>
                            <Form.Group controlId="priority">
                              <Form.Label>
                                Priority
                              </Form.Label>
                              <Controller
                                name="priority"
                                rules={{required:true}}
                                render={({ field }) => (
                                  <Form.Control
                                    {...field}
                                    isInvalid={!!errors.priority}
                                    as="select"
                                    custom
                                  >
                                    {lookups?.priorities.map((ci) => (
                                      <option key={ci.code} value={ci.code}>
                                        {ci.label}
                                      </option>
                                    ))}
                                  </Form.Control>
                                )}
                                control={control}
                                defaultValue=""
                              />
                              {errors.priority && (
                                <Form.Control.Feedback type="invalid">
                                  {errors.priority.message}
                                </Form.Control.Feedback>
                              )}
                            </Form.Group> */}
                            <Form.Row>
                              <Col lg={6} md={12}>
                                <Form.Group>
                                  <Form.Label>Date Due</Form.Label>
                                  <Controller
                                    name="dueDate"
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                      <Form.Control
                                        {...field}
                                        isInvalid={!!errors.dueDate}
                                        size="sm"
                                        type="date"
                                      />
                                    )}
                                    control={control}
                                  />
                                  {errors.dueDate && (
                                    <Form.Control.Feedback type="invalid">
                                      {errors.dueDate.message}
                                    </Form.Control.Feedback>
                                  )}
                                </Form.Group>
                              </Col>
                            </Form.Row>
                          </>
                        </Col>
                      </Row>
                      <Row>
                        <Col className={"d-flex"} sm={4}>
                          <ServiceRequestDropzone
                            submissionID={serviceRequestID}
                            setUploadedFiles={setUploadedFiles}
                          />
                        </Col>
                        <Col sm={4}>
                          <ServiceRequestFileList
                            uploadedFiles={uploadedFiles}
                            deleteFile={deleteFile}
                          />
                        </Col>
                        <Col sm={4}>
                          {/* <pre>{JSON.stringify(props.formConfig, null, 2)}</pre>` */}

                          {/* {lookups?.formConfig.showTechConnect && (
                            <FieldGroup
                              label={lookups?.formConfig.techConnectLabel}
                              defaultValue=""
                              required
                              controlId="techConnectNumber"
                              errors={errors}
                              register={register}
                            />
                          )}

                          {lookups?.formConfig.showCostCenter && (
                            <FieldGroup
                              label={lookups?.formConfig.costCenterLabel}
                              defaultValue=""
                              required
                              controlId="costCenterNumber"
                              errors={errors}
                              register={register}
                            />
                          )} */}
                        </Col>
                      </Row>
                    </fieldset>
                  </Card.Body>
                </RaiseableCard>
              </Col>
            </Row>
            <Row>
              <Col style={{ marginTop: "10px", marginBottom: "10px" }}>
                {/* Quote: {JSON.stringify(quote)} {quote ? "✔" : "✖"} */}
                <Button className={"float-right"} type="submit">
                  Submit quotation request
                </Button>
                {/* {serviceRequestID} */}
              </Col>
            </Row>
          </Form>
        </>
      )}
    </>
  );
};

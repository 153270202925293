import { API, Auth } from "aws-amplify";
import { format, parseJSON } from "date-fns";
import { useMutation, useQuery, useQueryClient } from "react-query";
import axios from "axios";
import { NewNoteCommand, DeleteNoteCommand } from "../components/CommandTypes";
import {
  FileInfo,
  QuotationViewModel,
  ServiceRequestViewModel,
} from "./SharedTypes";
import { QuotationView } from "../components/QuotationView";

export interface UpdateQuoteCommand {
  requestID: string;
  serviceRequestID: string;
  simProID: string;
  simProSiteID: string;
  billingAddress: string;
  workSiteName: string;
  workSiteEmail: string;
  workSitePhone: string;
  workSiteMobile: string;
  workSiteAddress: string;
  jobDescription: string;
  dueDate: string;
  useCustomWorkSiteContact: boolean;
}

export interface acceptanceProps {
  requestID: string;
  startDate: string;
  endDate: string;
  jobType: string;
  priority: string;
  costCenter: string;
  techConnect: string;
}

const parseDate = (str: string) => {
  return format(parseJSON(str), "yyyy-MM-dd");
};

const updateQuote = async (command: UpdateQuoteCommand) => {
  console.log("updating quote...", command);
  const response = (await API.put("TeltracSRPortal", "/quotation", {
    body: command,
  })) as QuotationViewModel;
  response.jobDetails.dueDate = parseDate(response.jobDetails.dueDate);
  console.log("done", response);
  return response;
};

const getQuotation = async (requestId: string) => {
  const response = (await API.get("TeltracSRPortal", "/quotation", {
    queryStringParameters: { id: requestId },
  })) as QuotationViewModel;
  response.jobDetails.dueDate = parseDate(response.jobDetails.dueDate);
  return response;
};

const getQuotationAttachments = async (requestId: string) => {
  const response = await API.get(
    "TeltracSRPortal",
    `/quotation/attachments/${requestId}`,
    null
  );
  return response as FileInfo[];
};

const acceptQuote = async (requestId: string, props: acceptanceProps) => {
  API.post("TeltracSRPortal", `/quotation/${requestId}/approve`, {
    body: props,
  });
};

const uploadQuotationAttachment = async (requestId: string, file: File) => {
  const formData = new FormData();
  formData.append("file", file);
  const session = await Auth.currentSession();
  const token = session.getIdToken().getJwtToken();
  const endpoint = await API.endpoint("TeltracSRPortal");
  const url = `${endpoint}/quotation/${requestId}/attachment`;
  console.log("posting attachment");
  return await axios.post(url, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });
};

const deleteQuotationAttachment = async (
  requestId: string,
  filename: string
) => {
  API.del(
    "TeltracSRPortal",
    "/quotation/$[requestId}/attachment/{$filename}",
    {}
  );
};

function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const useQuotation = (requestId: string) => {
  const queryClient = useQueryClient();

  const refreshRequest = () => {
    queryClient.invalidateQueries(["quotation", requestId]);
    queryClient.invalidateQueries(["submission", requestId]);
    queryClient.invalidateQueries("quotationList");
  };

  return {
    quotation: useQuery<QuotationViewModel>(["quotation", requestId], () =>
      getQuotation(requestId)
    ),
    attachments: useQuery<FileInfo[]>(["quoteAttachments", requestId], () =>
      getQuotationAttachments(requestId)
    ),
    deleteAttachment: useMutation(
      (filename: string) => deleteQuotationAttachment(requestId, filename),
      {
        onSuccess: (data) => {
          console.log("delete file success: ", data);
        },
      }
    ),
    updateQuoteMutation: useMutation(updateQuote, {
      onSuccess: (data) => {
        queryClient.setQueryData(["quotation", requestId], data);
        queryClient.invalidateQueries("quotationList");
        queryClient.invalidateQueries(["submission", requestId]);
      },
    }),
    acceptQuoteMutation: useMutation(
      (props: acceptanceProps) => acceptQuote(requestId, props),
      {
        onSuccess: async (data) => {
          const old = queryClient.getQueryData<QuotationViewModel>([
            "quotation",
            requestId,
          ]);
          if (!!old) {
            old.status = "Accepted";
            queryClient.setQueryData<QuotationViewModel>(
              ["quotation", requestId],
              old
            );
          }
          await sleep(10000);
          refreshRequest();
        },
      }
    ),
    uploadQuotationAttachmentMutator: useMutation(
      (file: File) => uploadQuotationAttachment(requestId, file),
      {
        onSuccess: () =>
          queryClient.invalidateQueries(["quoteAttachments", requestId]),
      }
    ),
  };
};

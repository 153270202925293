import React from 'react';
import { Row, Col, Button, Badge, Table } from 'react-bootstrap';
import { Link, useRouteMatch } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortUp, faSortDown, faPlusCircle, faFilter, faTimes } from '@fortawesome/free-solid-svg-icons';
import { LinkContainer } from "react-router-bootstrap";
import { useTable, useSortBy } from 'react-table';

const ClientUsers = props => {

    const { path, url } = useRouteMatch();

    const data = React.useMemo(() => props.users.filter(x => x.org?.toUpperCase() === props.match.params.clientID?.toUpperCase()), [props.users, props.match.params.clientID]);
    const columns = React.useMemo(() => [
        {
            Header: "Name",
            id: "userName",
            accessor: (row, i) => `${row.firstName} ${row.surname}`,
            Cell: ({ row }) => <Link to={`${url}/${row.original.userName}`} className={!row.original.enabled && "text-secondary"} >{row.original.firstName} {row.original.surname}</Link>
        },
        {
            Header: "Email",
            accessor: "email",
        },
        {
            Header: "Info",
            id: "InfoColumn",
            accessor: (row, i) => `${row.enabled} ${row.isCustomerManager} ${row.isCustomerLevel1}`,
            Cell: ({ row }) => <span>
                {!row.original.enabled && <><Badge variant="secondary">Disabled</Badge>{" "}</>}
                {row.original.isCustomerManager && <><Badge variant="info">Manager</Badge>{" "}</>}
                {row.original.isCustomerLevel1 && <><Badge variant="primary">Level 1 Admin</Badge>{" "}</>}
            </span>
        }
    ], []);

    const instance = useTable(
        {
            data: data,
            columns: columns,
        },
        useSortBy
    );

    return (
        <>
            <Row>
                <h3>{props.match.params.clientID} Users</h3>
            </Row>
            <Row>
                <Col>
                    <LinkContainer to={`${url}/new-user`}>
                        <Button variant="link">
                            <FontAwesomeIcon icon={faPlusCircle} /> New User
                        </Button>
                    </LinkContainer>

                    <Table {...instance.getTableProps}>
                        <thead>
                            {instance.headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map(column => (
                                        <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                            {column.render('Header')}
                                            <span>
                                                {column.isSorted
                                                    ? column.isSortedDesc
                                                        ? ' 🔽'
                                                        : ' 🔼'
                                                    : ''}
                                            </span>
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...instance.getTableBodyProps()}>
                            {instance.rows.map((row, i) => {
                                instance.prepareRow(row)
                                return (
                                    <tr {...row.getRowProps()} >
                                        {row.cells.map(cell => {
                                            return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                        })}
                                    </tr>
                                )

                            })}
                        </tbody>
                    </Table>

                    {
                        //<Table>
                        //    <thead>
                        //        <th>Name</th>
                        //        <th>Email</th>
                        //        <th>Info</th>
                        //    </thead>
                        //    <tbody>
                        //        {props.users.filter(x => x.org?.toUpperCase() === props.match.params.clientID?.toUpperCase())
                        //            .map(x =>
                        //                <tr key={x.userName}>
                        //                    <td>
                        //                        <Link to={`${url}/${x.userName}`} className={!x.enabled && "text-secondary"} >{x.firstName} {x.surname}</Link>
                        //                    </td>
                        //                    <td>
                        //                        {x.email}
                        //                    </td>
                        //                    <td>
                        //                        {!x.enabled && <><Badge variant="secondary">Disabled</Badge>{" "}</>}
                        //                        {x.isCustomerManager && <><Badge variant="info">Manager</Badge>{" "}</>}
                        //                        {x.isCustomerLevel1 && <><Badge variant="primary">Level 1 Admin</Badge>{" "}</>}
                        //                    </td>
                        //                </tr>
                        //            )}
                        //    </tbody>
                        //</Table>
                    }
                </Col>
            </Row>
        </>
    );
}

export default ClientUsers;
import { API } from "aws-amplify";
import { useQuery } from "react-query";
import { SiteInfoVM } from "./SharedTypes";

const fetchSiteInfo = async (siteInfoId : string) => {
    console.log("fetchSiteInfo", siteInfoId);
    return await API.get(
        "TeltracSRPortal",
        '/submission/SiteInfo',
        { queryStringParameters: { siteInfoID : siteInfoId } }
    );
}


export const useSiteInfo = (siteInfoId? : string ) => {
    return {
        siteInfo : useQuery<SiteInfoVM>(['siteInfo', siteInfoId], () => fetchSiteInfo(siteInfoId!), {enabled:!!siteInfoId} )
    };
}
import { API } from "aws-amplify";
import { format, parseJSON } from "date-fns";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { ServiceRequestViewModel, UserPermissions } from "./SharedTypes";

export interface ServiceRequestListing {
    clientID : string
    requestID : string
    userIdentifier : string
    simProID : string
    serviceRequestID : string
    dateCreated : string
    dueDate : string
    yourName : string
    supplier : string
    billTo : string
    jobDescription : string
    jobType : string
    priority : string
    assignedTo : string
    assignedToName : string
    status : string
    quoteAccepted : boolean
    workSiteAddress : string
    type : string
    quoteStatus : string
    customerStatus : string
    simProQuoteId : string
    simProJobId : string
    userPermissions : UserPermissions
}

export interface CreateServiceRequestCommand {
    requestID: string
    serviceRequestID: string
    simProID: string
    simProSiteID: string
    billingAddress: string
    workSiteName: string
    workSiteEmail: string
    workSitePhone: string
    workSiteMobile: string
    workSiteAddress: string
    jobDescription: string
    techConnectNumber: string
    costCenterNumber: string
    jobType: string
    priority: string
    startDate: string
    endDate: string
    useCustomWorkSiteContact: boolean
  }

const createServiceRequest = async (command : CreateServiceRequestCommand) => {
    return (await API.post("TeltracSRPortal", "/serviceRequest", {body:command})) as ServiceRequestViewModel;
  }

const getServiceRequests = async () => {
    const response = await API.get("TeltracSRPortal", "/servicerequest/list", undefined) as ServiceRequestListing[];
    const mappedResponse = response.map(x => {
        x.dateCreated = format(
            parseJSON(x.dateCreated),
            "yyyy-MM-dd"
          );
          x.dueDate = format(
            parseJSON(x.dueDate),
            "yyyy-MM-dd"
          );
          return x;
    });
          return mappedResponse;
}

export const useServiceRequestList = () => {

    const queryClient = useQueryClient();

    return {
        serviceRequests:  useQuery<ServiceRequestListing[]>("serviceRequestList", getServiceRequests),
        createServiceRequestMutation: useMutation(createServiceRequest, {onSuccess : (data) => {
            queryClient.setQueryData(["serviceRequest", data.serviceRequestID], data)
            queryClient.invalidateQueries("serviceRequestList")
        }
        })
};
}

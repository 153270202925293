/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-2",
    "aws_cognito_identity_pool_id": "ap-southeast-2:dd8b0f61-88c4-4fdb-bc70-f944c1af9662",
    "aws_cognito_region": "ap-southeast-2",
    "aws_user_pools_id": "ap-southeast-2_zoGSOvT8A",
    "aws_user_pools_web_client_id": "6n2k83dv9icru9tn3ightl7qic",
    "oauth": {}
};


export default awsmobile;

import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { API } from 'aws-amplify';

const AdminHome = props => {



    const copyDataFromCSV = () => {
        API.post("TeltracSRPortal", "/admin/copycsv")
            .then(response => {
                console.log(response);
            });
    }

    const copyUsersToDB = () => {
        API.post("TeltracSRPortal", "/admin/copyusers")
            .then(response => {
                console.log(response);
            });
    }

    return <ul>
        <li>
            <Link to={`${props.url}/staff`}>Staff</Link>
        </li>
        <li>
            <Link to={`${props.url}/clients`}>Clients</Link>
        </li>
        {
            props.user && props.user.attributes.email === "iwitham@resourceware.co.nz" &&
            <>
                <Button onClick={copyDataFromCSV}>Copy Data From CSV</Button>
                <Button onClick={copyUsersToDB}>Copy Users To DB</Button>
            </>
        }
    </ul>

}

export default AdminHome;
import React from 'react';
import { API } from 'aws-amplify';
import { Row, Table, Button, Modal, Form } from 'react-bootstrap';
import { useDebounceCallback } from '@react-hook/debounce';


const RemoveCustomerModal = props => {

    const { onCancel, onRemove, record } = props;

    return <Modal show={record !== null}>
        <Modal.Header>
            <Modal.Title>Remove From Customer Group?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {record &&
                <div>
                    <p>SimPro Customer ID: {record.simProCustomerID}</p>
                    <p>Company Name: {record.companyName}</p>
                </div>

            }
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={onCancel}>Cancel</Button>{" "}
            <Button variant="danger" onClick={onRemove}>Remove</Button>
        </Modal.Footer>
    </Modal>
}

const AddNewCustomerModal = props => {
    const { show, onCancel, addCustomerToGroup } = props;

    const [customerFromSimpro, setCustomerFromSimpro] = React.useState(null);
    const [promise, setPromise] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [notFound, setNotFound] = React.useState(null);

    const LookupCustomer = useDebounceCallback(id => {
        console.log("ID: ", id);
        setLoading(true);
        setNotFound(null);
        setCustomerFromSimpro(null);

        if (promise) {
            API.cancel(promise, "Request superceded");
        }

        setPromise(
            API.get("TeltracSRPortal", '/admin/customer', {
                queryStringParameters: {
                    simproCustomerID: id
                }
            }).then(response => {
                console.log("Request done");
                setLoading(false);
                setCustomerFromSimpro(response);
            }).catch(error => {
                setLoading(false);
                if (API.isCancel(error)) {
                    console.log("Request cancelled: ", error.message);
                } else {
                    setNotFound(id);
                    console.log("Request error: ", error.message);
                }
            }));
    }, 400);

    const cancel = () => {
        setCustomerFromSimpro(null);
        onCancel();
    };

    const onAddCustomer = () => {
        addCustomerToGroup(customerFromSimpro.simProCustomerID);
        onCancel();
    }

    return <Modal show={show} >
        <Modal.Header>
            <Modal.Title>Lookup Customer From SimPro</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form.Group>
                <Form.Label>SimPro Customer ID</Form.Label>
                <Form.Control size="lg" type="text" onChange={evt => LookupCustomer(evt.target.value)} />
            </Form.Group>

            {loading && <p>Loading...</p>}
            {notFound && <p>Customer with ID <strong>{notFound}</strong> not found</p>}
            {customerFromSimpro && <>
                <h5>{customerFromSimpro.companyName}</h5>
                <p>{customerFromSimpro.postalAddress}</p>
            </>}
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={cancel}>Cancel</Button>{" "}
            <Button variant={customerFromSimpro ? "success" : "secondary"} disabled={!customerFromSimpro} onClick={onAddCustomer}>Add To Customer Group</Button>
        </Modal.Footer>

    </Modal>
}

const ClientCustomersSimpro = props => {

    const [data, setData] = React.useState([]);
    const [recordToRemove, setRecordToRemove] = React.useState(null);
    const [showNewCustomerModal, setShowNewCustomerModal] = React.useState(false);

    const onCancelNewCustomerModal = () => {
        setShowNewCustomerModal(false);
    }

    const onCancelRemoveModal = () => {
        setRecordToRemove(null);
    }

    const removeRecord = () => {

        let simProCustomerID = recordToRemove.simProCustomerID;

        // optimistic update...
        setData(data.filter(x => x.simProCustomerID !== simProCustomerID));
        setRecordToRemove(null);

        // real update.
        API.del(`TeltracSRPortal`, `/admin/customer`, {
            'queryStringParameters': {
                'organisationID': props.match.params.clientID,
                'simproCustomerID': simProCustomerID
            }
        }).then(response => {
            // refresh customer data
            API.get("TeltracSRPortal", "/admin/customers?organisationID=" + props.match.params.clientID).then(response => {
                console.log(response);
                setData(response);
            });
        });


    }

    const addCustomerToGroup = id => {
        console.log("Adding customer to group:", id);
        API.post('TeltracSRPortal', '/admin/addCustomerToGroup',
            { body: { customerGroup: props.match.params.clientID, simproCustomerID: id } })
            .then(response => {
                // refresh customer data
                API.get("TeltracSRPortal", "/admin/customers?organisationID=" + props.match.params.clientID).then(response => {
                    console.log(response);
                    setData(response);
                });
            })
    }

    React.useEffect(() => {
        // get customer data
        const FetchData = async () => {

            let response = await API.get("TeltracSRPortal", "/admin/customers?organisationID=" + props.match.params.clientID);
            console.log(response);
            setData(response);

        };

        FetchData();
    }, []);

    return <div>
        <RemoveCustomerModal onCancel={onCancelRemoveModal} record={recordToRemove} onRemove={removeRecord} />
        <AddNewCustomerModal onCancel={onCancelNewCustomerModal} show={showNewCustomerModal} addCustomerToGroup={addCustomerToGroup} />
        <Row>
            <h3>{props.match.params.clientID} Customers</h3>
        </Row>
        <Row>
            <Table>
                <thead>
                    <th>Simpro Customer ID</th>
                    <th>Company Name</th>
                    <th><Button variant="outline-success" onClick={() => setShowNewCustomerModal(true)}>Add New</Button></th>
                </thead>
                <tbody>
                    {data && data.map(x => <tr key={x.simProCustomerID}>
                        <td>{x.simProCustomerID}</td>
                        <td>{x.companyName}</td>
                        <td><Button variant="outline-danger" onClick={() => setRecordToRemove(x)}>Remove</Button></td>
                    </tr>)}
                </tbody>
            </Table>
        </Row>
    </div>
}

export default ClientCustomersSimpro;
import API from "@aws-amplify/api";
import { format, parseJSON } from "date-fns";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { QuotationViewModel, ServiceRequestViewModel, UserPermissions } from "./SharedTypes";

export interface QuotationListing {
  clientID: string;
  requestID: string;
  userIdentifier: string;
  simProID: string;
  serviceRequestID: string;
  dateCreated: string;
  dueDate: string;
  yourName: string;
  supplier: string;
  billTo: string;
  jobDescription: string;
  jobType: string;
  priority: string;
  assignedTo: string;
  assignedToName: string;
  status: string;
  quoteStatus: string;
  customerStatus: string;
  workSiteAddress: string;
  userPermissions: UserPermissions;
  quoteAccepted: boolean;
}

export interface CreateQuotationCommand {
  requestID: string
  serviceRequestID: string
  simProID: string
  simProSiteID: string
  billingAddress: string
  workSiteName: string
  workSiteEmail: string
  workSitePhone: string
  workSiteMobile: string
  workSiteAddress: string
  jobDescription: string
  dueDate: string
  useCustomWorkSiteContact: boolean
}

const createQuotation = async (command : CreateQuotationCommand) => {
  return (await API.post("TeltracSRPortal", "/quotation", {body:command})) as QuotationViewModel;

}

const parseDate = (str : string) => {
  return format(parseJSON(str), 'yyyy-MM-dd');
}

const getQuotations = async () => {
  const response = (await API.get("TeltracSRPortal", "/quotation/list", undefined)) as QuotationListing[]
  const mappedResponse  = response.map(x => {
    const mapped = {...x};
    mapped.quoteStatus = x.quoteAccepted ? "ARCHIVED" : x.quoteStatus;
    return mapped as QuotationListing;
  });
  
  return mappedResponse;

};

export const useQuotations = () => {

  const queryClient = useQueryClient();

  return {
    quotations: useQuery<QuotationListing[]>("quotationList", getQuotations),
    createQuotationMutation: useMutation(createQuotation, {onSuccess : (data) => {
      queryClient.setQueryData(["quotation", data.serviceRequestID], data)
      queryClient.invalidateQueries("quotationList")
    }})
  } 

};

import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { API } from 'aws-amplify';
var generator = require('generate-password');


const ResetPasswordModal = props => {
    const { show, setShow, email, userID } = props;

    const randomPassword = () => {
        return generator.generate({
            length: 8,
            excludeSimilarCharacters: true
        });
    }

    const [permanent, setPermanent] = React.useState(false);

    const [password, setPassword] = React.useState(randomPassword());

    const handleClose = () => setShow(false);

    const resetPassword = () => {
        // reset password...
        API.post("TeltracSRPortal", "/admin/resetPassword", { body: { userName: userID, password: password, permanent: permanent, email: email } })
            .then(() => {
                handleClose();
            });
    }

    return <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton><Modal.Title>Reset Password</Modal.Title></Modal.Header>
        <Modal.Body>
            <p>Resetting a user's password can be useful when a user's temporary password is lost or has expired, as the “forgot password” link will not work in these cases.
            </p>
            <Form onSubmit={evt => { evt.preventDefault(); evt.stopPropagation(); }}>
                <Form.Group controlId="formBasicEmail">
                    <Form.Label>Password</Form.Label>
                    <Form.Control minLength={8} required type="text" value={password} onChange={evt => setPassword(evt.target.value)} placeholder="New Password" />
                    <Form.Text className="text-muted">Minimum length 8</Form.Text>
                    <Button onClick={() => setPassword(randomPassword())}>Random</Button>
                </Form.Group>
                <Form.Group>
                    <Form.Check
                        value={permanent}
                        onChange={evt => { console.log(evt); setPermanent(evt.target.checked); }}
                        type="checkbox"
                        checked={permanent}
                        label="Permanent" />
                    {permanent ?
                        <Form.Text className="text-muted">Permanent passwords will <em>not</em> be emailed to the user. The user will not be prompted to change this password when they log on.</Form.Text>
                        : <Form.Text className="text-muted">User will emailed this temporary password and prompted to change it when they log on</Form.Text>
                    }
                </Form.Group>
            </Form>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>Cancel</Button>
            <Button disabled={password.length < 8} variant="primary" onClick={resetPassword}>Reset Password</Button>
        </Modal.Footer>
    </Modal >;
}

export default ResetPasswordModal;